<template>
    <v-dialog
        v-model="internal_value"
        :fullscreen="$vuetify.breakpoint.xsOnly || fullscreen"
        :persistent="persistent"
        :width="responsive_width(width['xs'], width['s'], width['md'], width['lg'], width['xl'])"
        :max-width="max_width || '100vw'"
        @click:outside="$emit('click_outside')"
        @keydown.esc="$emit('click_escape')"
        id="ada-dialog"
    >
        <template #activator="{ on, attrs }">
            <slot
                :attrs="attrs"
                :on="on"
                name="activator"
            />
        </template>
        <!-- 90% is max height of dialog -->
        <v-card
            :disabled="disabled"
            :height="fullscreen ? '100vh' : height || 'auto'"
            :loading="loading"
            id="app_dialog"
            class="app-object--relative app-flex__container"
        >
            <div
                v-if="!disable_header"
                class="app-flex__header"
            >
                <slot name="header">
                    <v-toolbar
                        ref="header"
                        width="100%"
                        dense
                    >
                        <v-row
                            justify="center"
                            style="max-width: 100%"
                            no-gutters
                        >
                            <v-col cols="9">
                                <v-toolbar-title class="app-text--highlighted title pa-0 app-text--overflow-hidden-ellipsis text-center">
                                    {{ translate(title) }}
                                </v-toolbar-title>
                            </v-col>
                        </v-row>

                        <div class="app-object--absolute-top-right mr-2">
                            <slot name="toolbar_actions">
                                <!-- Slotted toolbar_actions go here -->
                            </slot>
                            <v-btn
                                v-if="!preventClose"
                                @click="internal_value = false"
                                icon
                            >
                                <v-icon> mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </v-toolbar>
                </slot>
            </div>

            <v-card
                :class="{
                    'app-flex__content': true,
                    'app-object--no-border-top-radius': remove_border_top_radius,
                    'app-object--no-border-bottom-radius': remove_border_bottom_radius,
                }"
                flat
            >
                <slot name="default">
                    <!-- Slotted default go here -->
                </slot>
            </v-card>
            <div class="app-flex__footer">
                <slot name="footer">
                    <!-- Slotted footer goes here -->
                </slot>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'

export default {
    name: 'AppDialog',
    components: {},
    mixins: [common_mixin],
    props: {
        value: {
            type: Boolean /*Choose one*/,
        },
        title: String,
        persistent: Boolean,
        preventClose: Boolean,
        xxSmall: Boolean,
        xSmall: Boolean,
        small: Boolean,
        medium: Boolean,
        height: String,
        fullscreen: Boolean,
        disable_header: Boolean,
        transparent: Boolean,
        max_width: String,

        remove_border_top_radius: Boolean,
        remove_border_bottom_radius: Boolean,
        loading: Boolean,
        disabled: Boolean,
    },
    data() {
        return {
            internal_value: undefined,
        }
    },
    computed: {
        width() {
            if (this.xxSmall) return { xs: '', s: '50vw', md: '40vw', lg: '30vw', xl: '25vw' }
            if (this.xSmall) return { xs: '', s: '60vw', md: '45vw', lg: '35vw', xl: '35vw' }
            if (this.small) return { xs: '', s: '60vw', md: '55vw', lg: '45vw', xl: '45vw' }
            if (this.medium) return { xs: '', s: '90vw', md: '75vw', lg: '65vw', xl: '65vw' }

            return { xs: '', s: '100vw', md: '90vw', lg: '90vw', xl: '90vw' }
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value(val) {
            this.internal_value = val
        },

        internal_value: {
            handler(val) {
                this.$emit('input', val)
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {
        this.internal_value = this.value
    },
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style lang="sass" scoped></style>
