<template>
    <component
        v-if="property.access_right !== 'none'"
        v-model="internal_value"
        :allowed="property.enum"
        :attribute="attribute"
        :attribute_parent_values.sync="internal_attribute_parent_values"
        :autofocus="autofocus"
        :error_messages="error_messages"
        :filter="property.filter"
        :hide-details="hideDetails"
        :hint="hint"
        :label="label"
        :loading="!exists(property)"
        :placeholder="placeholder"
        :prefix="prefix"
        :property="property"
        :readonly="readonly"
        :reference_resource="reference_resource"
        :required="property && property.is_required"
        :resource="resource"
        :rules="rules"
        :update_on_change="update_on_change"
        :is="component"
    />
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { item_mixin } from '@/mixins/item'
import form_mixin from '@/mixins/form/mixin'

export default {
    name: 'ItemDisplayInput',
    props: {
        value: Object,
        resource: String,
        attribute: String,
        item_schema: Object,
        error: Object, // Not implemented on every sub component. Do it when used (String has)
        attribute_parent_values: Object,
        custom_label: String,
        custom_property: Object, // Used when you do not have an item_schema
        update_on_change: Boolean, // Not implemented on every sub component. Do it when used
        autofocus: Boolean, // Not implemented on every sub component. Do it when used
        show_optional: Boolean,
        hideDetails: Boolean, // Not implemented on every sub component. Do it when used
        disabled: Boolean, // Not implemented on every sub component. Do it when used
        prefix: String,
    },
    mixins: [item_mixin, form_mixin],
    components: {
        String: () => import(/*webpackChunkName: "ItemDisplayInputString"*/ './String.vue'),
        Boolean: () => import(/*webpackChunkName: "ItemDisplayInputBoolean"*/ './Boolean.vue'),
        DateTime: () => import(/*webpackChunkName: "ItemDisplayInputDateTime"*/ './DateTime.vue'),
        Reference: () => import(/*webpackChunkName: "ItemDisplayInputReference"*/ './Reference.vue'),
        Enum: () => import(/*webpackChunkName: "ItemDisplayInputEnum"*/ './Enum.vue'),
        Number: () => import(/*webpackChunkName: "ItemDisplayInputNumber"*/ './Number.vue'),
        Integer: () => import(/*webpackChunkName: "ItemDisplayInputInteger"*/ './Integer.vue'),
        TextArea: () => import(/*webpackChunkName: "TextArea"*/ './TextArea'),
        HtmlArea: () => import(/*webpackChunkName: "HtmlArea"*/ './HtmlArea'),
        Slider: () => import(/*webpackChunkName: "Slider"*/ './Slider'),
    },
    data() {
        return {}
    },
    computed: {
        rules() {
            const rules = []

            if (this.required) {
                rules.push(this.required_rule)
            }
            if (this.property.minLength) {
                rules.push((value) => {
                    return this.min_length_rule(value, this.property.minLength)
                })
            }
            if (this.property.maxLength) {
                rules.push((value) => {
                    return this.max_length_rule(value, this.property.maxLength)
                })
            }
            if (this.property.pattern) {
                rules.push((value) => {
                    return this.field_rule(value, this.property.pattern, this.attribute_name)
                })
            }
            if (this.property.luhn) {
                rules.push((value) => {
                    return this.luhn_rule(value)
                })
            }
            if (this.property.type === 'number') {
                rules.push(this.number_rule)
            }
            if (this.property.type === 'integer') {
                rules.push(this.integer_rule)
            }
            if (!isNaN(this.property.min)) {
                rules.push(this.new_number_min(this.property.min))
            }
            if (!isNaN(this.property.max)) {
                rules.push(this.new_number_max(this.property.max))
            }
            if (this.property.greater_than_date) {
                rules.push((value) => {
                    return this.greater_than_date_rule(value, this.property.greater_than_date)
                })
            }
            if (this.property.less_than_date) {
                rules.push((value) => {
                    return this.less_than_date_rule(value, this.property.less_than_date)
                })
            }

            return rules
        },
        readonly() {
            return (
                !this.exists(this.property) || this.property.readonly || this.property.access_right === 'read' || this.property.excluded || this.property.disabled || this.disabled
            )
        },
        required() {
            return this.is_required(this.property)
        },
        reference_resource() {
            if (!this.property.$ref) {
                return null
            }

            return this.property_reference_to_resource(this.property.$ref)
        },
        placeholder() {
            const translate_key = `placeholder.${this.attribute_name}`
            const translation = this.translate(translate_key)

            if (translate_key === translation) {
                return ''
            }

            return translation
        },
        hint() {
            const translate_key = `hint.${this.attribute_name}`
            const translation = this.translate(translate_key)

            if (translate_key === translation) {
                return ''
            }

            return translation
        },
        label() {
            if (this.custom_label) {
                return this.custom_label
            }

            let suffix = ''
            if (this.required && !this.readonly) {
                suffix = '*'
            } else if (this.show_optional) {
                suffix = ` (${this.translate('common.optional').toLowerCase()})`
            }

            return this.locale_key(this.attribute_name, this.translate_locations, true) + suffix
        },
        attribute_name() {
            let attribute_name = this.attribute.split('.').at(-1)
            if (!isNaN(attribute_name)) {
                attribute_name = this.attribute.split('.').at(-2)
            }

            return attribute_name
        },
        component() {
            if (this.property.$ref) {
                return 'Reference'
            } else if (this.property.enum) {
                return 'Enum'
            } else if (this.property.type === 'string') {
                if (this.property.format === 'date-time') {
                    return 'DateTime'
                } else if (this.property.description?.includes('#text_area')) {
                    return 'TextArea'
                } else if (this.property.description?.includes('#html_area')) {
                    return 'HtmlArea'
                }
                return 'String'
            } else if (this.property.type === 'boolean') {
                return 'Boolean'
            } else if (this.property.type === 'number') {
                return 'Number'
            } else if (this.property.type === 'integer') {
                if (this.property.description?.includes('#slider')) {
                    return 'Slider'
                }
                return 'Integer'
            }

            return null
        },
        property() {
            if (this.custom_property) {
                return this.custom_property
            }

            return this.get_property(this.item_schema, this.attribute)
        },
        error_messages() {
            const error_message = this.deep_get(this.internal_error, '_issues')?.[this.attribute]
            return error_message ? [this.translate(error_message)] : []
        },
        internal_error: {
            get() {
                return this.error
            },
            set(val) {
                this.$emit('update:error', val)
            },
        },
        internal_attribute_value: {
            get() {
                return this.deep_get(this.value, this.attribute, false)
            },
            set(val) {
                this.set_nested_property(this.internal_value, this.attribute, val)
            },
        },
        internal_attribute_parent_values: {
            get() {
                return this.attribute_parent_values
            },
            set(val) {
                this.$emit('update:attribute_parent_values', val)
            },
        },
        internal_value: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        internal_attribute_value() {
            if (this.error_messages && this.error_messages.length >= 1) {
                let issues = this.deep_get(this.internal_error, '_issues')
                delete issues[this.attribute]
                this.set_nested_property(this.internal_error, '_issues', issues)
            }
        },
    },
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style lang="sass" scoped></style>
