<template>
    <v-snackbars :objects.sync="internal_value">
        <template #action="{ close, index }">
            <v-btn
                v-if="internal_value[index]?.secondary_action != null"
                :loading="internal_value[index].loading"
                :disabled="internal_value[index].loading"
                @click="run_action(index)"
                color="brand_primary"
                text
            >
                {{ translate(internal_value[index].secondary_action.text, internal_value[index].secondary_action.text_params) }}
            </v-btn>
            <v-btn
                :disabled="internal_value[index]?.loading"
                @click="close"
                color="transparent"
                depressed
                fab
                small
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbars>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import VSnackbars from 'v-snackbars'
import locale_mixin from '@/mixins/common/locale_mixin'

export default {
    name: 'TheSnackbars',
    props: {},
    mixins: [locale_mixin],
    components: {
        VSnackbars,
    },
    data() {
        return {
            internal_value: [],
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState(['snackbar_messages']),
    },
    watch: {
        snackbar_messages(messages) {
            const message = messages[messages.length - 1]
            message.message = this.translate(message['text'])
            message.loading = false
            this.internal_value.push(message)
        },
    },
    methods: {
        async run_action (index) {
            const message = this.internal_value[index]
            message.loading = true
            try {
                await message.secondary_action.handler?.()
                const index_after = this.internal_value.indexOf(message)
                if (index_after >= 0) this.internal_value.splice(index_after, 1)
            } finally {
                message.loading = false
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
