<template>
    <v-chip
        :color="colors['background']"
        :dense="dense"
        :disabled="disabled"
        :label="label"
        :large="large"
        :light="light"
        :size="size"
        :small="small"
        :style="clickable ? '' : 'pointer-events: none'"
        :text-color="colors['text']"
        :x-large="xLarge"
        :x-small="xSmall"
        @click="$emit('click')"
    >
        <slot name="prepend">
            <v-icon
                v-if="icon"
                :color="colors['text']"
                :small="small"
                left
            >
                {{ icon }}
            </v-icon>
        </slot>
        <div
            :class="{ 'app-text--overflow-hidden-ellipsis': value_max_width }"
            :style="{ 'max-width': value_max_width ? value_max_width + 'px' : 'auto' }"
        >
            {{ internal_value }}
        </div>

        <slot name="append" />
    </v-chip>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: 'AppChip',
    components: {},
    mixins: [],
    props: {
        value: String,
        color: String,
        textColor: String,
        dark: Boolean,
        light: Boolean,
        dense: Boolean,
        disabled: Boolean,
        large: Boolean,
        small: Boolean,
        xLarge: Boolean,
        xSmall: Boolean,
        size: [Number, String],
        icon: String,
        clickable: Boolean,
        label: Boolean,
        value_max_width: [Number, String],
    },
    data() {
        return {
            internal_value: undefined,
        }
    },
    computed: {
        colors() {
            if (this.color?.includes('main')) {
                const color = this.color.split('_')[0]
                return {
                    text: `${color}_dark`,
                    background: `${color}_light`,
                }
            }
            return {
                text: this.textColor || 'primary',
                background: this.color,
            }
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value: {
            handler(val, old_val) {
                if (old_val === undefined) return
                this.$emit('input', val)
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {
        this.internal_value = this.value
    },
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style lang="sass" scoped></style>
