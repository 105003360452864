import { mapActions, mapGetters, mapState } from 'vuex'

import locale_mixin from '@/mixins/common/locale_mixin'
import { swagger_mixin } from '@/mixins/swagger'

export default {
    mixins: [locale_mixin, swagger_mixin],
    data() {
        return {}
    },
    computed: {
        ...mapGetters({}),
        ...mapState({}),
    },
    methods: {
        form_label(name, required, excluded) {
            if (excluded) return this.translate(name)

            if (required) return `${this.translate(name)} *`

            return this.translate(name)
        },
        form_rules(rules, excluded) {
            if (excluded) return []

            return rules
        },
        required_rule(val) {
            if (val === null || val === undefined) return this.$t('common.required')
            return true
        },
        organization_no_regex(val) {
            let regex = this.get_swagger_x_data('x-regular-expressions')['SWEDISH_COMPANY']
            let match = val.match(regex)
            if (!match) return this.$t('regex.swedish_company')
            return true
        },
        iban_se_regex(val) {
            if (!val) return true

            let regex = this.get_swagger_x_data('x-regular-expressions')['IBAN_SE']
            let match = val.match(regex)
            if (!match) return this.$t('regex.iban_se')
            return true
        },
        iban_regex(val) {
            if (!val) return true

            let regex = this.get_swagger_x_data('x-regular-expressions')['IBAN']
            let match = val.match(regex)
            if (!match) return this.$t('regex.iban')
            return true
        },
        bic_regex(val) {
            if (!val) return true

            let regex = this.get_swagger_x_data('x-regular-expressions')['BIC']
            let match = val.match(regex)
            if (!match) return this.$t('regex.bic')
            return true
        },
        plusgiro_regex(val) {
            if (!val) return true

            let regex = this.get_swagger_x_data('x-regular-expressions')['PLUSGIRO_NUMBER']
            let match = val.match(regex)
            if (!match) return this.$t('regex.plusgiro')
            return true
        },
        bankgiro_regex(val) {
            if (!val) return true

            let regex = this.get_swagger_x_data('x-regular-expressions')['BANKGIRO_NUMBER']
            let match = val.match(regex)
            if (!match) return this.$t('regex.bankgiro')
            return true
        },
        clearing_number_regex(val) {
            if (!val) return true

            let regex = this.get_swagger_x_data('x-regular-expressions')['CLEARING_NUMBER']
            let match = val.match(regex)
            if (!match) return this.$t('regex.clearing_number')
            return true
        },
        account_number_regex(val) {
            if (!val) return true

            let regex = this.get_swagger_x_data('x-regular-expressions')['ACCOUNT_NUMBER']
            let match = val.match(regex)
            if (!match) return this.$t('regex.account_number')
            return true
        },
        swedish_person_regex(val) {
            if (!val) return true

            let regex = this.get_swagger_x_data('x-regular-expressions')['SWEDISH_PERSON']
            let match = val.match(regex)
            if (!match) return this.$t('regex.swedish_person')
            return true
        },
        new_number_min(min_number) {
            return (val) => {
                if (val && val < min_number) {
                    return this.translate('rules.value_must_be_higher_than', { 0: min_number })
                }
                return true
            }
        },
        new_number_max(max_value) {
            return (val) => {
                if (val && val > max_value) {
                    return this.translate('rules.value_must_be_lower_than', { 0: max_value })
                }
                return true
            }
        },
        decimals_not_allowed(val) {
            if (val % 1 === 0) {
                return true
            }

            return this.translate('number.decimals_not_allowed')
        },
        ...mapActions([]),
    },
}
