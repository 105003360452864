<template>
    <v-scroll-y-transition>
        <section
            v-show="visible"
            :data-notification-id="item._id"
            class="px-6"
        >
            <v-divider />
            <v-card
                :to="target_route_path"
                class="px-2 py-2 my-2"
                color="transparent d-flex justify-space-between align-center"
                outlined
            >
                <div>
                    <div :class="[text_class, 'mb-4 app-text--overflow-hidden-ellipsis']">
                        {{ text }}
                    </div>
                    <div
                        class="d-flex align-center"
                        style="gap: 8px"
                    >
                        <v-chip
                            color="brand_secondary"
                            label
                            x-small
                        >
                            {{ chip }}
                        </v-chip>
                        <div class="caption app-opacity--level-7">|</div>
                        <div class="caption app-opacity--level-7 text-uppercase">
                            {{ item['creditor_name'] }}
                        </div>
                    </div>
                </div>
                <div>
                    <v-btn
                        v-if="hiding_available"
                        :disabled="!has_patch_access('creditor--notifications')"
                        @click.prevent="$emit('hide')"
                        href="#"
                        class="mr-2"
                        outlined
                        x-small
                    >
                        {{ translate('common.hide') }}
                        <v-icon right> mdi-eye-off </v-icon>
                    </v-btn>
                    <v-badge
                        :color="read_by_user ? 'transparent' : 'brand_primary'"
                        dot
                        inline
                    />
                </div>
            </v-card>
        </section>
    </v-scroll-y-transition>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import locale_mixin from '@/mixins/common/locale_mixin'
import object_mixin from '@/mixins/common/object_mixin'
import access_mixin from '@/mixins/common/access_mixin'

export default {
    name: 'TheDashboardTodoListItem',
    props: {
        item: {
            type: Object,
        },
        index: {
            type: Number,
        },
        intersection_observer: {
            type: IntersectionObserver,
        },
        loading: {
            type: Boolean,
        },
    },
    mixins: [locale_mixin, object_mixin, access_mixin],
    components: {},
    data() {
        return {
            visible: false,
        }
    },
    computed: {
        text() {
            switch (this.item.type) {
                case 'new_decision':
                    const language = this.current_language === 'sv' ? 'sv' : 'en'
                    const dotted_path = `data.decision_title.${language}`
                    const decision = this.deep_get(this.item, dotted_path)
                    const name = this.item.data.debtor_name || this.item.data.customer_name
                    return `${decision} | ${name}`
                default:
                    return this.translate(`notifications.${this.item.type}`, this.item.data)
            }
        },
        chip() {
            switch (this.item.type) {
                case 'new_decision':
                    return this.translate('common.decision')
                case 'new_ticket_response':
                    return this.translate('common.tickets')
                default:
                    return this.translate('common.activities')
            }
        },
        read_by_user() {
            return (this.item.read_by_users ?? []).some(({ user, read }) => user === this.selected_token_json.u_id && read != null)
        },
        text_class() {
            return this.read_by_user ? '' : 'font-weight-medium'
        },
        hiding_available() {
            return !['new_decision', 'new_ticket_response'].includes(this.item.type)
        },
        target_route_path() {
            if (this.item.target_resource == null) return

            switch (this.item.target_resource) {
                case 'decisions':
                    return `/decision/${this.item.data.decision}`
                case 'tickets':
                    return `/tickets/amili/ticket?ticket_id=${this.item.data.ticket}`
                default: {
                    const resource_id = this.item.data?.[this.item.target_resource_data_key]
                    if (resource_id == null) return
                    return `/item-display/${this.item.target_resource}/${resource_id}`
                }
            }
        },
        ...mapGetters(['selected_token_json']),
        ...mapState([]),
    },
    watch: {
        visible(after) {
            if (after) this.start_read_observer()
            else this.stop_read_observer()
        },
        loading(after) {
            if (!after) this.start_read_observer()
            else this.stop_read_observer()
        },
    },
    methods: {
        start_read_observer() {
            if (document.visibilityState !== 'visible') return
            if (this.loading) return
            if (this.$el != null) this.intersection_observer?.observe(this.$el)
        },
        stop_read_observer() {
            if (this.$el != null) this.intersection_observer?.unobserve(this.$el)
        },
        on_visibility_change() {
            if (document.visibilityState === 'visible') this.start_read_observer()
            else this.stop_read_observer()
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        document.addEventListener('visibilitychange', this.on_visibility_change)
        const max_timeout = 300
        const timeout = Math.min(this.index * 100, max_timeout)
        setTimeout(() => {
            this.visible = true
            if (timeout === max_timeout) this.$emit('completed')
        }, timeout)
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {
        document.removeEventListener('visibilitychange', this.on_visibility_change)
        this.stop_read_observer()
    },
    destroyed() {},
}
</script>
<style scoped></style>
