<template>
    <v-stepper
        v-if="internal_steps"
        v-model="current_step_number"
        style="background: transparent"
    >
        <ProgressBar
            :completed_steps.sync="completed_steps"
            :current_step_number.sync="current_step_number"
            :disabled_steps.sync="disabled_steps"
            :stepper_disabled="disabled"
            :steps.sync="internal_steps"
        />

        <v-card
            :disabled="disabled"
            class="py-5"
            outlined
        >
            <v-btn
                v-if="internal_current_step?.back && current_step_index !== 0 && !disabled_steps[current_step_index - 1]"
                :color="internal_current_step?.back?.color || ''"
                @click="() => back(internal_current_step?.back?.callback)"
                class="ml-5"
                text
            >
                <v-icon
                    class="mr-2"
                    small
                >
                    mdi-arrow-left
                </v-icon>
                {{ translate(internal_current_step?.back?.label || 'common.back') }}
            </v-btn>

            <v-stepper-items>
                <v-stepper-content
                    v-for="(_, index) of internal_steps"
                    :key="`stepper_step_${index}`"
                    :step="index + 1"
                >
                    <Step
                        v-model="internal_value"
                        :completed_steps.sync="completed_steps"
                        :disabled_steps.sync="disabled_steps"
                        :error.sync="internal_error"
                        :index="index"
                        :steps.sync="internal_steps"
                        :step_showing="current_step_index === index"
                        :step_shown="current_step_index > index"
                        class="pt-3 pb-3 px-5"
                    />
                </v-stepper-content>
            </v-stepper-items>

            <v-btn
                v-if="internal_current_step?.next"
                :color="internal_current_step?.next?.color || ''"
                :disabled="!completed_steps[current_step_index]"
                @click="() => next(internal_current_step?.next?.callback)"
                class="ml-5"
            >
                {{ translate(internal_current_step?.next?.label || 'common.next') }}
            </v-btn>
        </v-card>
    </v-stepper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin.js'
import ProgressBar from './progress_bar'
import Step from './Step.vue'

export default {
    name: 'AppStepperNew',
    components: { Step, ProgressBar },
    mixins: [common_mixin],
    props: {
        steps: Array,
        value: Object,
        error: Object,
        disabled: Boolean,
        disable_previous_steps_after_steps: Array,
        initial_step_number: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            current_step_number: this.initial_step_number,
            completed_steps: [],
            disabled_steps: [],
        }
    },
    computed: {
        internal_error: {
            get() {
                return this.error
            },
            set(val) {
                this.$emit('update:error', val)
            },
        },
        internal_value: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        internal_current_step: {
            get() {
                return this.deep_get(this.internal_steps, `${this.current_step_index}`, false)
            },
            set(value) {
                this.set_nested_property(this.internal_steps, `${this.current_step_index}`, value)
            },
        },
        internal_steps: {
            get() {
                return this.steps
            },
            set(value) {
                this.$emit('update:steps', value)
            },
        },
        current_step_index() {
            return this.current_step_number - 1
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        current_step_number(current_step_number, previous_step_number) {
            if (current_step_number > previous_step_number && this.disable_previous_steps_after_steps && this.disable_previous_steps_after_steps.includes(previous_step_number)) {
                const previous_step_index = previous_step_number - 1

                for (let step_index = previous_step_index; step_index >= 0; step_index--) {
                    this.set_nested_property(this.disabled_steps, `${step_index}`, true)
                }
            }
            this.$emit('step_changed', { current_step_number, previous_step_number: previous_step_number || null })
        },
    },
    methods: {
        async next(callback = null) {
            let step_number = this.current_step_number + 1

            if (callback) {
                const payload = {
                    props: this.internal_current_step?.props,
                    step_number: this.current_step_number,
                }
                step_number = (await callback(payload)) || step_number
            }

            if (this.current_step_number === this.steps.length) return

            this.current_step_number = step_number
        },
        async back(callback = null) {
            let step_number = this.current_step_number - 1

            if (callback) {
                const payload = {
                    props: this.internal_current_step?.props,
                    step_number: this.current_step_number,
                }
                step_number = (await callback(payload)) || step_number
            }

            if (this.current_step_number === 1) return

            this.current_step_number = step_number
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped>
.v-sheet.v-stepper:not(.v-sheet--outlined) {
    box-shadow: none;
}

.v-stepper__content {
    padding: 0;
}
</style>
