<template>
    <v-icon
        :large="large"
        :left="left"
        :right="right"
        :small="small"
        :x-large="xLarge"
        :x-small="xSmall"
    >
        {{ media_icon }}
    </v-icon>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: 'MediaFileIcon',
    props: {
        value: {
            type: [File, Object],
        },
        fallback_icon: String,
        xLarge: Boolean,
        large: Boolean,
        small: Boolean,
        xSmall: Boolean,
        left: Boolean,
        right: Boolean,
    },
    mixins: [],
    components: {},
    data() {
        return {
            icons: {
                'json': 'mdi-code-json',
                'csv': 'mdi-file-delimited',
                'html': 'mdi-language-html5',
                'jpeg': 'mdi-file-jpg-box',
                'png': 'mdi-file-png-box',
                'svg': 'mdi-svg',
                'x-bzip': 'mdi-zip-box',
                'x-bzip2': 'mdi-zip-box',
                'pdf': 'mdi-file-pdf-box',
                'markdown': 'mdi-language-markdown',
                'plain': 'mdi-format-text',
            },
        }
    },
    computed: {
        media_icon() {
            const content_type = this.value?.content_type || this.value?.type

            if (!content_type) {
                return this.fallback_icon || 'mdi-file-document-outline'
            }

            return this.icons[content_type.split('/').at(-1)] || this.fallback_icon || 'mdi-file-document-outline'
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
