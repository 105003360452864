<template>
    <v-form v-model="form_valid">
        <ContentTitle :title="translate('common.agreement')" />

        <small>{{ translate('common.select_package_and_agreement') }}</small>

        <v-radio-group
            v-model="internal_value_self_registration_template"
            :rules="[required_rule]"
            class="mt-0 address-radio-group"
            column
        >
            <div
                v-for="(self_registration_template, index) in self_registration_templates"
                :key="`self_registration_template_${index}`"
                class="mb-2"
            >
                <v-radio
                    :label="translate(self_registration_template.t_loc)"
                    :value="self_registration_template._id"
                    class="mb-1"
                    color="brand_primary"
                />

                <v-card-text class="pl-8 py-0 text--secondary">
                    {{ translate(self_registration_template.d_loc) }}
                </v-card-text>
            </div>
        </v-radio-group>

        <div class="my-4">
            <a
                class="text-decoration-underline"
                href="https://amili.se"
                target="_blank"
            >
                {{ translate('common.read_more_about_the_packages') }}
            </a>
        </div>
    </v-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'
import form_mixin from '@/mixins/form/mixin.js'
import ContentTitle from '@/components/content_title/Index.vue'

export default {
    name: 'SelfRegistrationRegistrationStepsAgreement',
    components: {
        ContentTitle,
    },
    mixins: [common_mixin, form_mixin],
    props: {
        value: Object,
        step_props: Object,
        step_completed: Boolean,
        step_disabled: Boolean,
        step_showing: Boolean,
        step_shown: Boolean,
    },
    data() {
        return {
            form_valid: false,
        }
    },
    computed: {
        is_completed() {
            return this.form_valid
        },
        self_registration_templates() {
            return this.selected_token_json?.srt_list || []
        },
        internal_value_self_registration_template: {
            get() {
                return this.deep_get(this.internal_value, 'self_registration_template', false)
            },
            set(value) {
                this.set_nested_property(this.internal_value, 'self_registration_template', value)
            },
        },
        internal_value: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        internal_step_props: {
            get() {
                return this.step_props
            },
            set(value) {
                this.$emit('update:step_props', value)
            },
        },
        internal_step_completed: {
            get() {
                return this.step_completed
            },
            set(value) {
                this.$emit('update:step_completed', value)
            },
        },
        internal_step_disabled: {
            get() {
                return this.step_disabled
            },
            set(value) {
                this.$emit('update:step_disabled', value)
            },
        },
        ...mapGetters(['selected_token_json']),
        ...mapState([]),
    },
    watch: {
        step_showing() {
            this.ensure_prefilled_data()
        },
        is_completed(boolean) {
            this.internal_step_completed = boolean
        },
    },
    methods: {
        ensure_prefilled_data() {
            if (this.step_showing && !this.internal_value_self_registration_template) {
                this.internal_value_self_registration_template = this.self_registration_templates?.[0]._id
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        this.ensure_prefilled_data()
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped></style>
