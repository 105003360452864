import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    data() {
        return {}
    },
    computed: {
        ...mapGetters([]),
        ...mapState(['creditor_case_configs', 'creditor_settings']),
    },
    methods: {
        async get_creditor_case_config(creditor_id) {
            if (this.creditor_case_configs?.[creditor_id]) {
                return this.creditor_case_configs[creditor_id]
            }

            const creditor = await this.get_creditor(creditor_id)

            if (creditor?.case_config) {
                await this.set_state_property({
                    state_property: 'creditor_case_configs',
                    data: creditor.case_config,
                    key: creditor._id,
                })

                return creditor.case_config
            }

            return null
        },
        async get_creditor(creditor_id) {
            let creditor = null

            await this.api_call_wrapper_v2(
                async () => {
                    const response = await this.api_get({
                        url: `/creditors/${creditor_id}`,
                    })

                    creditor = response.data
                },
                {
                    use_snackbar: false,
                }
            )

            return creditor
        },
        async get_creditor_settings(creditor_id, force_reload = false) {
            if (creditor_id == null) return

            const where = {
                creditor: creditor_id,
            }

            const creditor_settings_in_store = this.exists(this.creditor_settings[creditor_id])
            if (!force_reload && creditor_settings_in_store) {
                where['_updated'] = {
                    $gt: this.creditor_settings[creditor_id]._updated,
                }
            }

            try {
                const response = await this.api_get({
                    url: `/creditor--settings`,
                    params: {
                        where: where,
                    },
                })
                if (response?.status === 200) {
                    if (response.data._items.length) {
                        this.set_state_property({
                            state_property: 'creditor_settings',
                            key: creditor_id,
                            data: response.data._items[0],
                        })
                    } else if (!creditor_settings_in_store) {
                        await this.create_new_creditor_setting(creditor_id)
                    }
                }
            } catch (error) {
                console.log('Failed to load creditor--settings')
                console.log(error)
            }
        },
        async create_new_creditor_setting(creditor_id) {
            const new_creditor_settings = {
                creditor: creditor_id,
                settings: {
                    notification_types: {},
                    dashboard_statistics: {
                        hidden: []
                    }
                },
            }

            await this.api_post({
                url: '/creditor--settings',
                data: new_creditor_settings,
            })
            await this.get_creditor_settings(creditor_id, true)
        },
        ...mapActions(['set_state_property']),
    },
}
