<template>
    <section>
        <v-card
            class="d-flex flex-column"
            height="500"
            style="grid-area: todo"
            outlined
        >
            <section class="pt-6 pb-4 px-6">
                <div class="mb-6 subtitle-2">
                    {{ translate('common.todo') }}
                </div>
                <v-fade-transition>
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn
                                v-show="show_adjust_activities_trigger"
                                :disabled="!has_patch_access('creditor--settings')"
                                @click="show_adjust_activities()"
                                v-on="on"
                                v-bind="attrs"
                                class="app-object--absolute-top-right mt-5 mr-4"
                                text
                                x-small
                                fab
                            >
                                <v-icon> mdi-tune-vertical-variant</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ translate('common.adjust_creditor_notifications') }}</span>
                    </v-tooltip>
                </v-fade-transition>

                <v-chip-group
                    v-model="selected_filter_name"
                    mandatory
                    show-arrows
                >
                    <v-chip
                        v-for="[filter_name, filter] in Object.entries(filters)"
                        :key="filter_name"
                        :value="filter_name"
                        :color="selected_filter_name === filter_name ? 'brand_primary' : null"
                        :outlined="selected_filter_name !== filter_name"
                        class="my-0"
                        text-color="primary"
                        label
                    >
                        {{ translate(`common.${filter_name}`) }}
                        ({{ (filter.meta.total || 0) - (filter.meta.hidden || 0) }})
                    </v-chip>
                </v-chip-group>
            </section>
            <TheDashboardTodoList
                :key="creditor_id"
                :loading="loading"
                :filter_items="filter_items"
                :filter="selected_filter"
                :configurable="show_adjust_activities_trigger"
                @load_more="() => throttle_get_notifications(true)"
                @adjust_activities="show_adjust_activities"
                @read="add_to_read"
                @hide="set_notification_hide"
            />
        </v-card>

        <v-dialog
            v-model="show_dialog"
            width="500"
            persistent
        >
            <v-card :loading="saving">
                <v-card-title class="d-flex justify-space-between align-center">
                    <div class="text-h5">
                        {{ translate('common.adjust_creditor_notifications') }}
                    </div>
                    <v-btn
                        :disabled="saving"
                        @click="show_dialog = false"
                        class="ml-4"
                        icon
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <div class="mb-4">
                        {{ translate('common.adjust_creditor_notifications_help') }}
                    </div>

                    <div class="text--secondary font-weight-medium">
                        {{ translate('common.select_creditor_notification_types') }}
                    </div>
                    <v-checkbox
                        v-for="type in configurable_notification_types"
                        :key="type"
                        v-model="internal_value"
                        :value="type"
                        :label="translate(`common.${type}`)"
                        :disabled="saving"
                        dense
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        :disabled="saving"
                        @click="show_dialog = false"
                        outlined
                    >
                        {{ translate('common.cancel') }}
                    </v-btn>
                    <v-btn
                        :disabled="saving"
                        @click="submit_adjust_activities"
                        color="primary"
                    >
                        {{ translate('common.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </section>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { item_mixin } from '@/mixins/item'
import { swagger_mixin } from '@/mixins/swagger'
import common_mixin from '@/mixins/common/mixin'
import TheDashboardTodoList from '@/components/the_dashboard/todo/TodoList.vue'
import throttle from 'lodash/throttle'

export default {
    name: 'TheDashboardTodo',
    props: {
        creditor_id: {
            type: String,
        },
    },
    mixins: [item_mixin, swagger_mixin, common_mixin],
    components: { TheDashboardTodoList },
    data() {
        return {
            loading: true,
            selected_filter_name: 'all',
            filters: {
                all: {
                    meta: {},
                    types: ['new_decision', 'new_ticket_response'],
                    add_configurable_types: true,
                },
                decisions: {
                    meta: {},
                    types: ['new_decision'],
                },
                tickets: {
                    meta: {},
                    types: ['new_ticket_response'],
                },
                activities: {
                    meta: {},
                    types: [],
                    add_configurable_types: true,
                },
            },
            filter_items: [],
            read_ids: [],

            show_dialog: false,
            internal_value: [],
            saving: false,
            throttle_get_notifications: throttle(function (more = false) {
                this.get_notifications(more)
            }, 500),
        }
    },
    computed: {
        selected_filter: {
            get() {
                return this.filters[this.selected_filter_name]
            },
            set(val) {
                this.$set(this.filters, this.selected_filter_name, val)
            },
        },
        creditor_notification_settings() {
            return this.creditor_settings[this.creditor_id]?.settings?.notification_types ?? {}
        },
        configurable_notification_types() {
            return Object.keys(
                (this.swagger_schemas['creditor--settings'] ?? this.swagger_schemas_in_memory['creditor--settings'])?.properties?.settings?.properties?.notification_types
                    ?.properties ?? {}
            )
        },
        show_adjust_activities_trigger() {
            return this.creditor_id && this.selected_filter.add_configurable_types
        },
        unique_load_key() {
            if (!this.creditor_id || !this.selected_filter_name || !this.creditor_settings) {
                return null
            }

            const creditor_settings_etag = this.creditor_settings[this.creditor_id]?._etag

            return `${this.creditor_id}_${this.selected_filter_name}_${creditor_settings_etag}`
        },
        ...mapGetters(['selected_token_json']),
        ...mapState([]),
    },
    watch: {
        unique_load_key: {
            handler(val) {
                if (val !== null) {
                    this.commit_read_notifications()
                    this.filter_items = []
                    this.throttle_get_notifications()
                }
            },
            immediate: true,
        },
    },
    methods: {
        add_to_read(notification) {
            if (this.read_ids.findIndex((id) => id === notification._id) > -1) {
                return
            }

            const read_by_users = [...(notification.read_by_users ?? [])]
            const already_read_by_user = read_by_users.find(({ user }) => user === this.selected_token_json.u_id)
            if (already_read_by_user) {
                return
            }

            this.read_ids.push(notification._id)
        },
        async get_notifications(more = false) {
            if (!this.has_get_resource_access('creditor--notifications')) {
                return
            }

            this.loading = true
            try {
                const where = this.notification_where(this.selected_filter)
                if (where === null) {
                    return
                }

                if (more) {
                    this.selected_filter.meta.page += 1
                }

                const response = await this.api_get({
                    url: '/creditor--notifications',
                    params: {
                        where,
                        sort: '-_created',
                        page: this.selected_filter.meta.page || 0,
                        max_results: 10,
                    },
                })

                this.selected_filter.meta = response['data']['_meta']
                const new_items = response['data']['_items'].filter((item) => this.filter_items.findIndex((other_item) => item._id === other_item._id) === -1)
                this.filter_items = this.filter_items.concat(new_items)
            } finally {
                this.update_other_filters_meta_data()
                this.loading = false
            }
        },
        async update_other_filters_meta_data() {
            if (!this.has_get_resource_access('creditor--notifications')) {
                return
            }

            try {
                for (const [filter_name, filter] of Object.entries(this.filters)) {
                    if (filter_name === this.selected_filter_name) {
                        continue // No use in getting
                    }
                    const where = this.notification_where(filter)

                    if (where === null) {
                        continue
                    }
                    const response = await this.api_get({
                        url: '/creditor--notifications',
                        params: {
                            where,
                            projection: {
                                _id: 1,
                            },
                            max_results: 1,
                        },
                    })

                    this.set_nested_property(this.filters, `${filter_name}.meta`, response.data._meta)
                }
            } catch (e) {}
        },
        notification_where(filter) {
            const where = {
                hidden_by_creditor: { $ne: true },
                hidden_by_system: { $ne: true },
                type: {
                    $in: filter.types,
                },
            }
            if (this.creditor_id) {
                where['creditor'] = this.creditor_id
            }

            if (filter.add_configurable_types) {
                const enabled_types = Object.entries(this.creditor_notification_settings)
                    .filter(([, settings]) => settings.enabled)
                    .map(([type]) => type)
                where.type.$in = where.type.$in.concat(enabled_types)
            }

            return where
        },
        async read_notification(notification_id) {
            if (!this.has_patch_access('creditor--notifications')) return

            await this.api_call_wrapper_v2(
                async () => {
                    const notification = this.filter_items.find((item) => item._id === notification_id)

                    const read_by_users = [...(notification.read_by_users ?? [])]
                    read_by_users.push({ user: this.selected_token_json.u_id, read: new Date().toUTCString() })

                    await this.api_patch({
                        url: `/creditor--notifications/${notification._id}`,
                        data: {
                            read_by_users,
                        },
                        if_match: notification._etag,
                    })
                },
                {
                    error_callback: (e) => {
                        console.error(e)
                    },
                    use_snackbar: false,
                }
            )
        },

        async set_notification_hide(notification, hidden = true) {
            if (!this.has_patch_access('creditor--notifications')) return

            await this.api_call_wrapper_v2(
                async () => {
                    const response = await this.api_patch({
                        url: `/creditor--notifications/${notification._id}`,
                        data: {
                            hidden_by_creditor: hidden,
                        },
                        if_match: notification._etag,
                    })
                    this.$set(notification, 'hidden_by_creditor', hidden)
                    this.$set(notification, '_etag', response.data._etag)

                    this.$set(this.selected_filter.meta, 'hidden', (this.selected_filter.meta.hidden ?? 0) + (hidden ? 1 : -1))
                },
                {
                    error_callback: (e) => {
                        console.error(e)
                    },
                    success_text: hidden ? 'common.notification_hidden' : 'common.notification_unhidden',
                    success_secondary_action: hidden
                        ? {
                              handler: async () => {
                                  await this.set_notification_hide(notification, false)
                              },
                              text: 'common.undo',
                          }
                        : {},
                }
            )
        },

        async show_adjust_activities() {
            this.internal_value = Object.entries(this.creditor_notification_settings)
                .filter(([type, meta]) => meta.enabled === true)
                .map(([type, meta]) => type)
            this.show_dialog = true
        },
        async submit_adjust_activities() {
            this.saving = true
            try {
                const now = new Date()
                for (const type of this.configurable_notification_types) {
                    const should_be_enabled = this.internal_value.includes(type)
                    if (this.creditor_notification_settings[type]?.enabled !== should_be_enabled) {
                        this.update_creditor_setting(this.creditor_id, `settings.notification_types.${type}`, {
                            enabled: should_be_enabled,
                            from: now.toUTCString(),
                        })
                    }
                }
                await this.save_creditor_settings_updates(this.creditor_id)
                this.show_dialog = false
            } finally {
                this.saving = false
            }
        },
        async commit_read_notifications() {
            const promises = []
            for (const id of this.read_ids) {
                promises.push(this.read_notification(id))
            }
            await Promise.all(promises)
            this.read_ids = []
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        void this.get_swagger_schema('creditor--settings')
    },
    beforeUpdate() {},
    updated() {},
    async beforeDestroy() {
        await this.commit_read_notifications()
    },
    destroyed() {},
}
</script>
<style lang="scss" scoped>
::v-deep .v-slide-group__content {
    padding: 0;
}

::v-deep .v-slide-item--active {
    border: 1px solid transparent;
}

.v-chip:before {
    background-color: initial;
}
</style>
