<template>
    <v-row
        class="fill-height"
        no-gutters
    >
        <v-col v-if="$vuetify.breakpoint.mdAndUp" />
        <v-col
            :style="{ 'margin-bottom': $vuetify.breakpoint.mdAndUp ? '20vh' : '10vh' }"
            align-self="end"
            class="pr-4"
        >
            <div
                class="d-flex align-start mb-3"
                style="gap: 16px"
            >
                <div class="d-flex">
                    <img
                        alt="amili"
                        height="24"
                        src="@/assets/logo_black.png"
                    />
                    <span class="self-registration--logo--ease-suffix"> ease </span>
                </div>

                <AppEnvironmentChip />
            </div>

            <div class="text-h1 mb-4 font-weight-bold">
                {{ translate('common.register_an_account') }}
            </div>
            <p class="mb-9">
                {{ translate('common.register_an_account_help_text') }}
            </p>

            <v-row>
                <div
                    :class="{
                        'd-flex flex-column align-start px-3': true,
                        'full-width': $vuetify.breakpoint.xsOnly,
                    }"
                    style="gap: 1.25rem"
                >
                    <v-btn
                        @click="show_bankid_dialog = true"
                        class="full-width"
                        elevation="0"
                        dark
                    >
                        {{ translate('common.show_qr_code') }}
                        <img
                            alt="amili"
                            height="16"
                            src="@/assets/bankid.png"
                            style="filter: brightness(0) invert(1)"
                            class="ml-3"
                        />
                    </v-btn>
                    <v-btn
                        :class="{ 'full-width': $vuetify.breakpoint.smAndDown }"
                        @click="open_bankid_app"
                        elevation="0"
                        outlined
                    >
                        {{ translate('common.open_bankid_on_the_same_device') }}
                    </v-btn>
                </div>
            </v-row>

            <v-row class="mt-10">
                <v-col :class="{ 'text-center': $vuetify.breakpoint.xsOnly }">
                    <router-link :to="{ name: 'Login' }">
                        <a class="text-decoration-underline">
                            {{ translate('common.do_you_already_have_an_account') }}
                        </a>
                    </router-link>
                </v-col>
            </v-row>
        </v-col>

        <MobileBankIDDialog
            ref="mobile_bankid_dialog"
            v-model="show_bankid_dialog"
            @error="(e) => (mobile_bankid_error = e)"
            poll_endpoint="/self-registration/mobile-bankid"
        >
            <template #error_append>
                <router-link
                    v-if="all_associated_companies_already_registered"
                    :to="{ name: 'Login' }"
                >
                    <b class="text-decoration-underline error--text">
                        {{ translate('common.click_here_to_login') }}
                    </b>
                </router-link>
            </template>
        </MobileBankIDDialog>
    </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'
import AppEnvironmentChip from '@/components/app_environment_chip'
import MobileBankIDDialog from '@/components/mobile_bankid_dialog'

export default {
    name: 'SelfRegistrationIdentification',
    components: { AppEnvironmentChip, MobileBankIDDialog },
    mixins: [common_mixin],
    props: {},
    data() {
        return {
            show_bankid_dialog: false,
            mobile_bankid_error: null,
        }
    },
    computed: {
        all_associated_companies_already_registered() {
            return this.mobile_bankid_error?._issues?.includes('all_associated_companies_already_registered')
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        async open_bankid_app() {
            await this.$refs.mobile_bankid_dialog.open_app()
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped>
.self-registration--logo--ease-suffix {
    line-height: 2.1;
    margin-left: 5px;
}

.full-width {
    width: 100%;
}
</style>
