<template>
    <div :class="pb ? `pb-${pb}` : { 'pb-6': !dense, 'pb-4': dense }">
        <v-card
            :width="width || '100%'"
            color="transparent"
            flat
        >
            <v-row
                align="start"
                justify="space-between"
                no-gutters
            >
                <v-col>
                    <div
                        :class="{ 'text-h3': !dense, 'text-h4': dense, 'font-weight-medium': true }"
                        v-text="translate(title)"
                    />
                    <div
                        class="body-2 text--secondary"
                        v-text="translate(label)"
                    />
                </v-col>

                <slot name="buttons">
                    <!-- Slotted content goes here                -->
                </slot>
            </v-row>
        </v-card>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import locale_mixin from '@/mixins/common/locale_mixin'

export default {
    name: 'ContentTitle',
    components: {},
    mixins: [locale_mixin],
    props: {
        title: String,
        label: String,
        width: String,
        pb: String,
        dense: Boolean,
    },
    data() {
        return {
            // template: false,
        }
    },
    computed: {
        // template() {
        //     return this.something;
        // },
        ...mapGetters([
            // ...
        ]),
        ...mapState([
            // ...
        ]),
    },
    watch: {
        // template(v) {
        //     do stuff;
        // }
    },
    methods: {
        // template() {
        //
        // },
        ...mapActions([
            // ...
        ]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
