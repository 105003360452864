<template>
    <v-form v-model="form_valid">
        <ContentTitle
            :title="translate('common.your_account')"
            pb="3"
        />

        <v-card-text class="pa-0 pb-6">
            <span v-html="translate('common.we_found_x_companies_with_y_signatory', { x: `<b>${companies.length}</b>`, y: `<b>${name}</b>` })" />
        </v-card-text>

        <AppAutocomplete
            v-model="selected_organization_no"
            :custom_items="companies_items"
            :label="translate('common.select_invoice_issuer')"
            :rules="[required_rule]"
            @search_text="on_search_companies"
            @blur="on_blur_companies"
            item_text="text"
            item_value="value"
        >
            <template #item="{ item, on }">
                <v-list-item
                    :disabled="Boolean(item.is_registered)"
                    v-on="on"
                >
                    <v-list-item-content>
                        <div
                            :class="{ 'app-opacity--level-8': item.is_registered }"
                            class="d-flex justify-space-between align-center"
                        >
                            {{ item.text }}
                            <small v-if="item.is_registered">
                                {{ translate('common.is_registered') }}
                            </small>
                        </div>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </AppAutocomplete>

        <div v-show="selected_organization_no">
            <small>{{ translate('common.type_of_recipient') }}</small>

            <v-radio-group
                v-model="recipient_type"
                class="mt-0"
                row
            >
                <v-radio
                    v-for="(type, index) of recipient_types"
                    :key="`recipient_type_${index}`"
                    :label="translate(`common.${type}`)"
                    :value="type"
                    color="brand_primary"
                />
            </v-radio-group>

            <div
                v-for="(type, index) of recipient_types"
                :key="`recipient_number_${index}`"
            >
                <ItemDisplayInput
                    v-if="recipient_type === type"
                    v-model="internal_value"
                    :custom_label="translate(`common.enter_${type}_number`)"
                    :custom_property="vm[`${type}_custom_property`]"
                    :attribute="type"
                />
            </div>

            <ItemDisplayInput
                v-model="internal_value"
                :custom_label="translate('common.enter_standard_text_for_what_invoices_refer_to')"
                :custom_property="debt_description_custom_property"
                attribute="debt_description"
            />
        </div>
    </v-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'
import form_mixin from '@/mixins/form/mixin.js'
import AppAutocomplete from '@/components/app_autocomplete'
import ContentTitle from '@/components/content_title/Index.vue'
import ItemDisplayInput from '@/components/item_display_input/index.vue'

export default {
    name: 'SelfRegistrationRegistrationStepsAccount',
    components: {
        ItemDisplayInput,
        ContentTitle,
        AppAutocomplete,
    },
    mixins: [common_mixin, form_mixin],
    props: {
        value: Object,
        step_props: Object,
        step_completed: Boolean,
        step_disabled: Boolean,
        step_showing: Boolean,
        step_shown: Boolean,
    },
    data() {
        return {
            vm: this,
            form_valid: false,
            selected_organization_no: null,
            companies_search_text: null,
            recipient_types: ['bankgiro', 'plusgiro'],
            recipient_type: 'bankgiro',
        }
    },
    computed: {
        bankgiro_custom_property() {
            return {
                type: 'string',
                is_required: true,
                pattern: this.get_swagger_x_data('x-regular-expressions')['BANKGIRO_NUMBER'],
            }
        },
        plusgiro_custom_property() {
            return {
                type: 'string',
                is_required: true,
                pattern: this.get_swagger_x_data('x-regular-expressions')['PLUSGIRO_NUMBER'],
            }
        },
        debt_description_custom_property() {
            return {
                type: 'string',
                is_required: true,
                description: '#text_area',
                minLength: 1,
            }
        },
        companies_items() {
            return this.companies
                .map((company) => {
                    const text = `${company.nm} (${company.no})`
                    const value = company.no
                    const is_registered = company.is_reg

                    return { text, value, is_registered }
                })
                .filter((company) => {
                    const text = company.text.toLowerCase()
                    const search_text = this.companies_search_text?.toLowerCase() || ''

                    return text.includes(search_text)
                })
        },
        name() {
            return this.format_name(this.selected_token_json?.nm)
        },
        companies() {
            return this.selected_token_json?.co_list || []
        },
        internal_value_organization_no: {
            get() {
                return this.deep_get(this.internal_value, 'organization_no', false)
            },
            set(value) {
                this.set_nested_property(this.internal_value, 'organization_no', value)
            },
        },
        internal_value: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        internal_step_props: {
            get() {
                return this.step_props
            },
            set(value) {
                this.$emit('update:step_props', value)
            },
        },
        internal_step_completed: {
            get() {
                return this.step_completed
            },
            set(value) {
                this.$emit('update:step_completed', value)
            },
        },
        internal_step_disabled: {
            get() {
                return this.step_disabled
            },
            set(value) {
                this.$emit('update:step_disabled', value)
            },
        },
        ...mapGetters(['selected_token_json']),
        ...mapState([]),
    },
    watch: {
        selected_organization_no: {
            handler(value) {
                if (value) {
                    this.internal_value_organization_no = value
                }
            },
        },
        form_valid(boolean) {
            this.internal_step_completed = boolean
        },
        recipient_type(_, old_type) {
            this.set_nested_property(this.internal_value, old_type, undefined)
        },
    },
    methods: {
        on_search_companies(value) {
            this.companies_search_text = value
        },
        on_blur_companies() {
            if (!this.selected_organization_no) {
                this.selected_organization_no = this.internal_value_organization_no
            }

            this.companies_search_text = null
        },
        format_name(name) {
            return name
                .split(' ')
                .map((part) =>
                    part
                        .split('-')
                        .map((sub_part) => sub_part.charAt(0).toUpperCase() + sub_part.slice(1).toLowerCase())
                        .join('-')
                )
                .join(' ')
        },
        ensure_prefilled_data() {
            if (this.internal_value_organization_no) {
                this.recipient_type = this.recipient_types.find((key) => key in this.internal_value) || this.recipient_type
                this.selected_organization_no = this.internal_value_organization_no
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        this.ensure_prefilled_data()
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped></style>
