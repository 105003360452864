<template>
    <div>
        <small>{{ translate('common.enter_sender_address') }}</small>

        <v-radio-group
            v-model="address_input_type"
            class="mt-0 address-radio-group"
        >
            <template #message><span>&nbsp;</span></template>
            <div class="d-flex flex-column fill-width">
                <v-radio
                    :label="translate('common.select_address_from_the_swedish_companies_registration_office')"
                    class="mb-1"
                    value="selection"
                    color="brand_primary"
                />

                <v-container
                    v-if="address_input_type === 'selection'"
                    class="py-0"
                >
                    <v-row
                        v-if="addresses.length === 0"
                        class="pl-8"
                    >
                        <v-alert
                            class="my-6"
                            color="secondary"
                            dense
                            text
                        >
                            {{ translate('common.no_addresses_found') }}
                        </v-alert>
                    </v-row>

                    <v-row
                        v-else-if="addresses.length <= 4"
                        class="pl-5 my-0"
                    >
                        <v-col
                            v-for="(address, index) in addresses"
                            :key="`address_selector_${index}`"
                            md="3"
                            sm="6"
                            xs="12"
                            class="pr-0"
                        >
                            <v-card
                                :class="{ active: index === selected_address_index }"
                                @click="() => select_address(index)"
                                class="address-selector--card"
                                flat
                                outlined
                            >
                                <v-card-text class="px-3">
                                    <span style="white-space: nowrap">{{ address.address }}</span> <br />
                                    {{ address.zip_code }} {{ address.city }}<br />
                                    {{ address.country ? locale_key(address.country.toLowerCase(), ['country'], true) : '' }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row
                        v-else-if="addresses.length > 4"
                        class="pl-5 my-0"
                    >
                        <v-col
                            cols="12"
                            class="pr-0"
                        >
                            <AppAutocomplete
                                v-model="temp_selected_address_index"
                                :custom_items="address_items"
                                :label="translate('common.select_address')"
                                :rules="[required_rule]"
                                @search_text="on_search_addresses"
                                @blur="on_blur_addresses"
                                item_text="text"
                                item_value="value"
                                hide_details
                            >
                                <template #item="{ item, on }">
                                    <v-list-item
                                        v-on="on"
                                        class="px-2"
                                    >
                                        <v-list-item-content class="py-1">
                                            <v-card
                                                :class="{ active: item.value === selected_address_index }"
                                                class="address-selector--card"
                                                flat
                                                outlined
                                            >
                                                <v-card-text class="px-3">
                                                    {{ item.address }} <br />
                                                    {{ item.zip_code }} {{ item.city }}<br />
                                                    {{ item.country }}
                                                </v-card-text>
                                            </v-card>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </AppAutocomplete>
                        </v-col>
                    </v-row>
                </v-container>

                <v-radio
                    :label="translate('common.enter_a_different_address')"
                    :class="{ 'mb-6': address_input_type !== 'manual' }"
                    value="manual"
                    color="brand_primary"
                    class="mt-1"
                />

                <v-container
                    v-if="address_input_type === 'manual'"
                    class="pt-0 pb-1"
                >
                    <v-row class="pl-5 my-0">
                        <v-col
                            cols="12"
                            class="pb-0 pt-1"
                        >
                            <ItemDisplayInput
                                v-model="manual_address"
                                :custom_property="custom_property"
                                :custom_label="translate('common.address')"
                                attribute="address"
                            />
                        </v-col>
                        <v-col
                            class="pr-2 py-0"
                            cols="3"
                        >
                            <ItemDisplayInput
                                v-model="manual_address"
                                :custom_property="custom_property"
                                :custom_label="translate('common.zip_code')"
                                class="py-0"
                                attribute="zip_code"
                            />
                        </v-col>
                        <v-col
                            cols="9"
                            class="py-0"
                        >
                            <ItemDisplayInput
                                v-model="manual_address"
                                :custom_property="custom_property"
                                :custom_label="translate('common.city')"
                                class="py-0"
                                attribute="city"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-radio-group>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'
import form_mixin from '@/mixins/form/mixin.js'
import AppAutocomplete from '@/components/app_autocomplete/index.vue'
import ItemDisplayInput from '@/components/item_display_input/index.vue'

export default {
    name: 'SelfRegistrationRegistrationStepsContactAddressSelector',
    components: { ItemDisplayInput, AppAutocomplete },
    mixins: [common_mixin, form_mixin],
    props: {
        value: Object,
        addresses: Array,
    },
    data() {
        return {
            address_input_type: 'selection',
            manual_address: {},
            temp_selected_address_index: null,
            selected_address_index: null,
            addresses_search_text: null,
        }
    },
    computed: {
        custom_property() {
            return { type: 'string', minLength: 1, is_required: true }
        },
        address_items() {
            return this.addresses
                .map((address, index) => {
                    const value = index
                    const text = [address.address, `${address.zip_code} ${address.city}`, address.country].filter((address) => address.length).join(', ')

                    return { ...address, text, value }
                })
                .filter((address) => {
                    const text = address.text.toLowerCase()
                    const search_text = this.addresses_search_text?.toLowerCase() || ''

                    return text.includes(search_text)
                })
        },
        selected_address() {
            return Array.isArray(this.addresses) && Number.isInteger(this.selected_address_index) ? this.addresses[this.selected_address_index] : undefined
        },
        address() {
            if (this.address_input_type === 'selection') {
                return this.selected_address
            }

            return this.manual_address
        },
        internal_value: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        ...mapGetters({}),
        ...mapState([]),
    },
    watch: {
        address(value) {
            this.internal_value = value
        },
        temp_selected_address_index: {
            handler(value) {
                if (Number.isInteger(value)) {
                    this.selected_address_index = value
                }
            },
        },
    },
    methods: {
        select_address(index) {
            this.selected_address_index = index
        },
        on_search_addresses(value) {
            this.addresses_search_text = value
        },
        on_blur_addresses() {
            if (!Number.isInteger(this.temp_selected_address_index)) {
                this.temp_selected_address_index = this.selected_address_index
            }

            this.companies_search_text = null
        },
        ensure_prefilled_data() {
            if (this.internal_value && Object.entries(this.internal_value).length > 0) {
                const selected_address_index = this.addresses.findIndex((obj) => Object.entries(this.internal_value).every(([key, value]) => obj[key] === value))

                if (selected_address_index >= 0) {
                    this.selected_address_index = selected_address_index
                } else {
                    this.manual_address = this.internal_value
                    this.address_input_type = 'manual'
                }
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        this.ensure_prefilled_data()
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped>
.fill-width {
    width: 100%;
}

.address-selector--card {
    height: 100%;
    cursor: pointer;
}

.address-selector--card.active {
    background: #e1fcff;
    border: 1px solid #00e2e2;
}

.v-card--link:before {
    background: transparent;
}
</style>

<style>
.address-radio-group.v-input--radio-group > .v-input__control > .v-messages {
    display: none;
}

.address-radio-group.v-input--radio-group > .v-input__control > .v-input__slot {
    margin-bottom: 0;
}
</style>
