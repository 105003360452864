export default {
    methods: {
        async get_page_title(resource, resource_id, ui_structure_for_resource) {
            const translated_resource = resource ? this.translate(ui_structure_for_resource.search_header) : null

            if (!translated_resource) {
                return 'Amili'
            }

            if (!resource_id) {
                return `${translated_resource} | Amili`
            }

            const header = ui_structure_for_resource.item_display.header
            const prepend_component = header.prepend_component === 'HashTag' ? '#' : ''
            const title_key = header.title.attribute

            if (!title_key) {
                return 'Amili'
            }

            const response = await this.api_get({
                url: `/${resource}/${resource_id}`,
                params: {
                    projection: { [title_key]: 1 },
                },
            })

            if (!response.data[title_key]) {
                return 'Amili'
            }

            return `${translated_resource}: ${prepend_component}${response.data[title_key]} | Amili`
        },
    },
}
