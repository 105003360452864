<template>
    <div>
        <v-fade-transition>
            <v-chip
                v-show="show_select_creditor_button"
                @click="show_dialog = true"
                label
                outlined
                small
            >
                {{ translate('common.creditor') }}: {{ value['name'] || '-' }}
                <v-icon
                    right
                    small
                >
                    mdi-pencil
                </v-icon>
            </v-chip>
        </v-fade-transition>
        <v-dialog
            v-model="show_dialog"
            width="500"
            persistent
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ translate('common.select_creditor') }}
                </v-card-title>
                <v-card-text>
                    <AppAutocomplete
                        v-model="internal_value"
                        resource="creditors"
                        clearable
                        hide_details
                        return_object
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        @click="show_dialog = false"
                        outlined
                    >
                        {{ translate('common.cancel') }}
                    </v-btn>
                    <v-btn
                        @click="submit"
                        color="primary"
                    >
                        {{ translate('common.ok') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { item_mixin } from '@/mixins/item'
import AppAutocomplete from '@/components/app_autocomplete'

export default {
    name: 'TheDashboardSelectCreditor',
    props: {
        value: {
            type: Object,
        },
    },
    mixins: [item_mixin],
    components: { AppAutocomplete },
    data() {
        return {
            show_select_creditor_button: false,
            show_dialog: false,
            internal_value: null,
        }
    },
    computed: {
        ...mapGetters(['system_user']),
        ...mapState([]),
    },
    watch: {
        internal_value(data) {
            if (data) {
                const start_page_data = JSON.stringify({
                    name: data['name'],
                    _id: data['_id'],
                })
                localStorage.setItem('start_page', start_page_data)
            } else {
                localStorage.removeItem('start_page')
            }
        },
    },
    methods: {
        submit() {
            this.$emit('input', this.internal_value || {})
            this.show_dialog = false
        },
        async check_for_creditors() {
            const response = await this.api_get({
                url: '/creditors',
                params: {
                    projection: { _id: 1, name: 1 },
                    max_results: 1,
                },
            })
            const total = this.deep_get(response, 'data._meta.total') || 0
            this.show_select_creditor_button = total > 1 || this.system_user
            if (total === 1 && !this.system_user) {
                this.internal_value = response.data._items[0]
                this.submit()
            }

        },
        check_for_saved_data() {
            const saved_data = localStorage.getItem('start_page')
            if (saved_data) {
                this.internal_value = JSON.parse(saved_data)
                this.submit()
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
        this.check_for_creditors()
        this.check_for_saved_data()
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped></style>
