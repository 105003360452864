<template>
    <SelfRegistration />
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import SelfRegistration from '@/components/self_registration'

export default {
    name: 'ViewsSelfRegistration',
    components: { SelfRegistration },
    mixins: [],
    props: {},
    data() {
        return {}
    },
    computed: {
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped></style>
