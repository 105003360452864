<template>
    <AppDialog
        v-model="internal_value"
        max_width="550px"
        x-small
    >
        <template #header>
            <h5 class="py-4 px-6 d-flex justify-space-between align-center">
                <v-toolbar-title class="app-text--highlighted title pa-0 app-text--overflow-hidden-ellipsis">
                    {{ translate('common.mobile_bankid') }}
                </v-toolbar-title>

                <v-btn
                    @click="internal_value = false"
                    icon
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </h5>
        </template>

        <template #default>
            <div class="d-flex flex-column align-center">
                <v-card
                    :width="status === 'failed' || error ? '92%' : '200'"
                    class="mb-4 d-flex justify-center align-center"
                    height="200"
                    flat
                >
                    <div
                        v-if="error"
                        class="d-flex flex-column align-center"
                    >
                        <v-alert
                            class="mb-2"
                            type="error"
                            dense
                            text
                        >
                            <slot name="error_prepend" />
                            {{ error._locale_message ? translate(error._locale_message) : error._error?.message || translate('system.error') }}.
                            <slot name="error_append" />
                        </v-alert>
                    </div>

                    <div
                        v-else-if="status === 'failed'"
                        class="d-flex flex-column align-center"
                    >
                        <v-alert
                            class="mb-2"
                            type="warning"
                            dense
                            text
                        >
                            {{ hint_code_message_map[hint_code] }}.

                            <span
                                @click="init"
                                class="text-decoration-underline font-weight-bold"
                                style="cursor: pointer; white-space: nowrap"
                            >
                                {{ translate('common.try_again') }}
                            </span>
                        </v-alert>
                    </div>

                    <div
                        v-else-if="status === 'completed'"
                        class="d-flex flex-column align-center"
                    >
                        <v-icon
                            class="mb-4"
                            color="success"
                            size="48"
                        >
                            mdi-check
                        </v-icon>

                        <span> {{ translate(`common.${mode}_successful`) }}! </span>
                    </div>

                    <div
                        v-else-if="!qr_code || hint_code === 'userSign'"
                        class="d-flex flex-column align-center"
                    >
                        <v-progress-circular
                            class="mb-4"
                            color="primary"
                            size="48"
                            width="3"
                            indeterminate
                        />
                        <span v-if="hint_code === 'userSign'"> {{ translate(`common.${mode}_in_progress`) }}.. </span>
                    </div>

                    <v-img
                        v-else
                        :src="`data:image/svg+xml;base64,${qr_code}`"
                        class="d-flex justify-center align-center"
                    />
                </v-card>

                <v-card-text
                    class="text-center py-0"
                    style="max-width: 500px"
                >
                    {{ translate(`common.bankid_${mode}_qr_information`) }}
                </v-card-text>

                <v-card-text class="text-center py-2">
                    {{ translate('common.or') }}
                </v-card-text>

                <v-btn
                    :disabled="status === 'completed' || hint_code === 'userSign'"
                    :class="{
                        'mb-6': true,
                        'full-width': $vuetify.breakpoint.smAndDown,
                    }"
                    @click="open_app"
                    elevation="0"
                    outlined
                >
                    {{ translate('common.open_bankid_on_the_same_device') }}
                </v-btn>
            </div>
        </template>

        <template #footer>
            <v-divider class="mt-0" />

            <div class="d-flex justify-end pa-4">
                <v-btn
                    @click="internal_value = false"
                    color="secondary"
                    text
                >
                    {{ translate('common.cancel') }}
                </v-btn>
            </div>
        </template>
    </AppDialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'
import AppDialog from '@/components/app_dialog/index.vue'

export default {
    name: 'MobileBankIDDialog',
    components: { AppDialog },
    mixins: [common_mixin],
    props: {
        value: Boolean,
        signing: Boolean,
        signing_data: {
            type: Object,
            default: () => ({}),
        },
        signing_user_visible_data: String,
        create_signing_token_endpoint: String,
        init_endpoint: String,
        poll_endpoint: String,
        cancel_endpoint: String,
        completed_callback: Function,
    },
    data() {
        return {
            pre_auth_token: null,
            auto_start_token: null,
            signing_token: null,
            qr_code: null,
            status: null,
            hint_code: null,
            poll_interval: null,
            is_polling: false,
            abort_controller: null,
            error: null,
        }
    },
    computed: {
        mode() {
            return this.signing ? 'signing' : 'identification'
        },
        endpoints() {
            const default_create_signing_token_endpoint = '/mobile-bankid--signs/create-signing-token'
            const default_init = this.signing ? '/mobile-bankid--signs' : '/authenticates/mobile-bankid-init/{application}'
            const default_poll = this.signing ? `/mobile-bankid--signs/{signing_token}` : '/authenticates/mobile-bankid'
            const default_cancel = this.signing ? `/mobile-bankid--signs/{signing_token}` : '/authenticates/mobile-bankid'

            return {
                create_signing_token: this.create_signing_token_endpoint || default_create_signing_token_endpoint,
                init: this.apply_params_to_url(this.init_endpoint || default_init, {
                    application: process.env.VUE_APP_NAME,
                }),
                poll: this.apply_params_to_url(this.poll_endpoint || default_poll, {
                    signing_token: this.signing_token,
                }),
                cancel: this.apply_params_to_url(this.cancel_endpoint || default_cancel, {
                    signing_token: this.signing_token,
                }),
            }
        },
        hint_code_message_map() {
            return {
                userCancel: this.translate(`common.${this.mode}_was_canceled`),
                startFailed: this.translate(`common.${this.mode}_took_to_long`),
                expiredTransaction: this.translate(`common.${this.mode}_took_to_long`),
            }
        },
        internal_value: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        async internal_value(value) {
            if (value) {
                this.clear_poll_interval()

                if (this.signing) {
                    await this.create_signing_token()
                } else {
                    await this.init()
                }
            } else {
                if (this.qr_code) {
                    await this.cancel_poll()
                }
            }
        },
        error(e) {
            this.$emit('error', e)
        },
    },
    methods: {
        async create_signing_token() {
            let response = null

            this.error = null

            await this.api_call_wrapper_v2(
                async () => {
                    response = await this.api_post({
                        url: this.endpoints.create_signing_token,
                        token: this.pre_auth_token,
                        data: this.signing_data || {},
                    })
                },
                {
                    error_callback: (e) => {
                        console.log(e)
                        this.error = e.data
                    },
                    use_snackbar: false,
                }
            )

            if (response.status === 200 && response.data?.token) {
                this.signing_token = response.data.token
                await this.init()
            }
        },
        async init() {
            const url = this.signing ? this.endpoints.init : `${process.env.VUE_APP_API_ROOT}${this.endpoints.init}`
            let response = null
            let data = null

            this.hint_code = null
            this.status = null
            this.error = null
            this.abort_controller = new AbortController()

            await this.api_call_wrapper_v2(
                async () => {
                    if (this.signing) {
                        response = await this.api_post({
                            url,
                            token: this.pre_auth_token,
                            data: {
                                signing_token: this.signing_token,
                                user_visible_data: this.signing_user_visible_data,
                            },
                            signal: this.abort_controller.signal,
                        })
                        data = response.data
                    } else {
                        response = await fetch(url, {
                            method: 'POST',
                            body: JSON.stringify({}),
                            headers: { 'Content-Type': 'application/json' },
                            signal: this.abort_controller.signal,
                        })
                        data = await response.json()
                    }
                },
                {
                    error_callback: (e) => {
                        console.log(e)
                        this.error = e.data
                    },
                    use_snackbar: false,
                }
            )

            if (response.status === 200 && data) {
                if (this.signing) {
                    this.signing_token = data.token
                } else {
                    this.pre_auth_token = data.token
                }

                this.qr_code = data.qr
                this.auto_start_token = data.auto_start_token
                this.poll_interval = setInterval(this.poll, 1000)
            }
        },
        async poll() {
            if (this.is_polling) {
                return
            }
            this.is_polling = true

            let response = null

            await this.api_call_wrapper_v2(
                async () => {
                    response = await this.api_get({
                        url: this.endpoints.poll,
                        token: this.pre_auth_token,
                        signal: this.abort_controller.signal,
                    })
                },
                {
                    error_callback: (e) => {
                        if (e.name !== 'AbortError') {
                            this.error = e.data
                            this.cancel_poll()
                        } else {
                            this.clear_poll_interval()
                        }
                    },
                    use_snackbar: false,
                }
            )

            if (response && response.status === 200) {
                this.hint_code = response.data.hint_code
                this.status = response.data.status

                if (this.status === 'completed') {
                    const token_list = response.data.token_list

                    await this.cancel_poll()

                    if (token_list && token_list.length) {
                        this.update_multi_tab_data('user_tokens', {
                            token_list: token_list,
                            selected_token: 0,
                        })
                    }

                    this.$emit('completed', response.data)
                } else if (this.status === 'failed') {
                    await this.cancel_poll()
                } else {
                    this.qr_code = response.data.qr
                }
            }

            this.is_polling = false
        },
        async open_app() {
            if (!this.poll_interval) {
                if (this.signing) {
                    await this.create_signing_token()
                } else {
                    await this.init()
                }
            }

            if (this.signing) {
                localStorage.setItem('signing_token', this.signing_token)
            } else {
                localStorage.setItem('pre_auth_token', this.pre_auth_token)
            }

            const redirect = encodeURIComponent(window.location.href)
            const url = `https://app.bankid.com/?autostarttoken=${this.auto_start_token}&redirect=${redirect}`
            window.open(url, '_blank')
        },
        async cancel_poll() {
            this.clear_poll_interval()

            if (this.abort_controller) {
                this.abort_controller.abort()
            }

            await this.api_call_wrapper_v2(
                async () => {
                    await this.api_delete({
                        url: this.endpoints.cancel,
                        token: this.pre_auth_token,
                    })
                },
                {
                    use_snackbar: false,
                }
            )

            this.qr_code = null

            localStorage.removeItem('pre_auth_token')
            localStorage.removeItem('signing_token')
        },
        clear_poll_interval() {
            clearInterval(this.poll_interval)
            this.poll_interval = null
            this.is_polling = false
        },
        async ensure_token() {
            if (this.signing) {
                this.signing_token = localStorage.getItem('signing_token')
            } else {
                this.pre_auth_token = localStorage.getItem('pre_auth_token')
            }

            if (this.pre_auth_token || this.signing_token) {
                this.abort_controller = new AbortController()
                await this.poll()
            }

            localStorage.removeItem('pre_auth_token')
            localStorage.removeItem('signing_token')
        },
        apply_params_to_url(url, params) {
            return Object.entries(params).reduce((acc, [key, value]) => {
                const placeholder = `{${key}}`
                return acc.replace(placeholder, value)
            }, url)
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    async mounted() {
        await this.ensure_token()
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped></style>
