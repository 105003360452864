import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueTour from 'vue-tour'
import { init_cookie_policy } from '@/plugins/cookie_policy'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faUserSecret, faUser, faCircle, faCheck } from '@fortawesome/free-solid-svg-icons'

import './styles/custom/index.sass'

library.add(fab)
library.add(faUserSecret, faUser, faCircle, faCheck)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)

Vue.config.productionTip = false

require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)

if (!String.format) {
    String.format = function(format) {
        let args = Array.prototype.slice.call(arguments, 1)
        return format.replace(/{(\d+)}/g, (match, number) => {
            return typeof args[number] != 'undefined' ? args[number] : match
        })
    }
}

router.beforeEach((to, from, next) => {
    const to_mode = to.query.mode
    const from_mode = from.query.mode
    if (to_mode === from_mode) {
        next()
    } else if (store.state.user_has_unsaved_changes) {
        store.commit('mutate', {
            property: 'unsaved_changes_dialog',
            with: {
                display: true,
                callback: () => next(),
            },
        })
    } else {
        next()
    }
})

init_cookie_policy()

router.afterEach((to, from) => {
    const get_nested_value = (obj, path) => {
        if (!path) {
            return null
        }
        let value = obj
        for (let key of path.split('.')) {
            if (value[key] === undefined) return null
            value = value[key]
        }
        return value
    }

    store.commit('mutate', {
        property: 'view_context',
        with: {
            resource: to.meta.resource || get_nested_value(to, to.meta.resource_dotted_path) || null,
            resource_id: to.meta.resource_id_dotted_path ? get_nested_value(to, to.meta.resource_id_dotted_path) : null,
        },
    })
})

// Must be last in main.js
new Vue({
    i18n,
    router,
    store,
    vuetify,
    methods: {
        create_new_favicon(href) {
            const all_icons = document.querySelectorAll('link[rel~=\'icon\']')
            all_icons.forEach(x => {
                x.remove()
            })

            const link = document.createElement('link')
            link.rel = 'icon'
            link.href = href

            document.head.appendChild(link)
        },
    },
    mounted() {
        switch (location.host) {
            case 'localhost:8080':
                this.create_new_favicon('/favicon-local.png?v=2')
                break
            case 'app-test.amili.se':
                this.create_new_favicon('/favicon-test.png?v=2')
                break
            case 'app-sandbox.amili.se':
                this.create_new_favicon('/favicon-sandbox.png?v=2')
                break
            case 'app-stage.amili.se':
                this.create_new_favicon('/favicon-stage.png?v=2')
                break
            default:
                return
        }
    },
    render: (h) => h(App),
}).$mount('#app')
