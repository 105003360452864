import { mapActions, mapGetters, mapState } from 'vuex'
import { multi_tab_state_mixin } from '@/mixins/multi_tab_state/mixin'
import utils_mixin from '@/mixins/common/utils_mixin'

function clear_old_user_settings(user_settings, ui_structure) {
    let changed = false

    const item_settings = user_settings.settings.item_component ?? {}

    for (const [resource, resource_settings] of Object.entries(item_settings)) {
        const allowed_attributes = new Set(ui_structure[resource]?.item_search?.attributes ?? [])

        if (allowed_attributes.size === 0) {
            delete item_settings[resource]
            continue
        }

        for (const filter of resource_settings.filters ?? []) {
            // search_filter
            const original_search_filter = filter.search_filter ?? []
            const filtered_search_filter = original_search_filter.filter((entry) => allowed_attributes.has(entry.property))
            if (original_search_filter.length !== filtered_search_filter.length) {
                changed = true
                filter.search_filter = filtered_search_filter
            }

            // sort
            if (!allowed_attributes.has(filter.sort?.property)) {
                changed = true
                filter.sort = {
                    property: '_created',
                    order: 'descending',
                }
            }

            // displayed_attributes
            const original_displayed_attributes = filter.displayed_attributes ?? []
            const filtered_displayed_attributes = original_displayed_attributes.filter((attribute) => allowed_attributes.has(attribute))
            if (original_displayed_attributes.length !== filtered_displayed_attributes.length) {
                changed = true
                filter.displayed_attributes = filtered_displayed_attributes
            }
        }
    }

    return changed
}

export default {
    mixins: [multi_tab_state_mixin, utils_mixin],
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            selected_token_json: 'selected_token_json',
            selected_token: 'selected_token',
            token_type: 'token_type',
            user_is_authenticated: 'user_is_authenticated',
            ui_structure: 'ui_structure',
        }),
        ...mapState({
            user_tokens: (state) => state.user_tokens,
            user_extended_data: (state) => state.user_extended_data,
            user_settings: (state) => state.user_settings,
            user_favorite: (state) => state.user_favorite,
            signing_out: (state) => state.signing_out,
            partner_import_filters: (state) => state.partner_import_filters,
            partner_self_registration_data: (state) => state.partner_self_registration_data,
        }),
    },
    methods: {
        reset_user_data() {
            this.update_multi_tab_data('user_settings', {})
            this.set_state_properties([
                {
                    state_property: 'user_extended_data',
                    data: {},
                },
                {
                    state_property: 'user_settings_updates',
                    data: [],
                },
                {
                    state_property: 'user_favorite',
                    data: {},
                },
            ])
        },
        async load_user_data() {
            if (this.token_type !== 'UserToken' || !this.user_is_authenticated) return
            await Promise.all([this.get_user_extended_data(), this.get_user_favorite()])
            await this.get_user_settings() // This is dependent on get_user_extended_data

            await this.load_partner_user_data()

            return true
        },
        async load_partner_user_data() {
            if (this.user_extended_data['partner']) {
                if (this.has_get_resource_access('partner--import-filters') && !this.partner_import_filters) {
                    await this.get_partner_filters()
                }
                if (this.has_get_resource_access('partner-self-registration--data') && !this.partner_self_registration_data) {
                    try {
                        await this.get_partner_self_registration_data()
                    } catch (e) {
                        console.warn('Failed to load partner self registration data. User doesnt have access.')
                    }
                }
            }
        },
        async get_partner_filters() {
            await this.api_get({
                url: `/partner--import-filters`,
                commit: true,
                state_property: 'partner_import_filters',
            })
        },
        async get_partner_self_registration_data() {
            await this.api_get({
                url: '/partner-self-registration--data',
                commit: true,
                state_property: 'partner_self_registration_data',
            })
        },
        async get_user_favorite() {
            if (this.exists(this.user_favorite)) return

            try {
                let result = await this.api_get({
                    url: `/user--favorites`,
                    params: {
                        where: {
                            user: this.selected_token_json['u_id'],
                        },
                    },
                })
                if (result && result.status === 200) {
                    if (result.data._items.length === 0) {
                        await this.create_new_user_favorite()
                    } else {
                        this.set_state_property({
                            state_property: 'user_favorite',
                            data: result.data._items[0],
                        })
                    }
                }
            } catch (error) {
                console.log('Failed to load user--favorites')
            }
        },
        async create_new_user_favorite() {
            let new_user_favorite = {
                user: this.selected_token_json['u_id'],
                favorites: {},
            }

            for (let property of ['account', 'partner'].filter((x) => !!this.user_extended_data[x])) {
                new_user_favorite[property] = this.user_extended_data[property]
            }

            await this.api_post({
                url: '/user--favorites',
                data: new_user_favorite,
            })
            await this.get_user_favorite()
        },
        async get_user_settings(force_reload = false, clear_old_settings = true) {
            const where = {
                user: this.selected_token_json['u_id'],
            }

            const user_settings_in_store = this.exists(this.user_settings)
            if (!force_reload && user_settings_in_store) {
                where['_updated'] = {
                    $gt: this.user_settings._updated,
                }
            }

            try {
                const response = await this.api_get({
                    url: `/user--settings`,
                    params: {
                        where: where,
                    },
                })
                if (response?.status === 200) {
                    const user_settings = response.data._items?.[0]
                    if (user_settings) {
                        if (clear_old_settings && this.truthy(this.ui_structure)) {
                            const changed = clear_old_user_settings(user_settings, this.ui_structure)

                            if (changed) {
                                await this.api_patch({
                                    url: `/user--settings/${user_settings._id}`,
                                    data: {
                                        settings: user_settings.settings,
                                    },
                                    if_match: user_settings._etag,
                                })

                                return await this.get_user_settings(true, false)
                            }
                        }

                        this.set_state_property({
                            state_property: 'user_settings',
                            data: user_settings,
                        })
                    } else if (!user_settings_in_store) {
                        await this.create_new_user_setting()
                    }
                }
            } catch (error) {
                console.log('Failed to load user--settings')
                console.log(error)
            }
        },
        async create_new_user_setting() {
            let new_user_settings = {
                user: this.selected_token_json['u_id'],
                settings: {
                    ui: {
                        theme: 'light',
                        language: 'sv',
                    },
                    item_component: {},
                },
            }

            for (let property of ['account', 'partner'].filter((x) => !!this.user_extended_data[x])) {
                new_user_settings[property] = this.user_extended_data[property]
            }

            await this.api_post({
                url: '/user--settings',
                data: new_user_settings,
            })
            await this.get_user_settings()
        },
        async get_cases() {
            await this.api_get({
                url: '/cases',
                params: undefined,
                commit: true,
                state_property: 'cases',
            })
            return true
        },
        async get_user_extended_data() {
            if (this.exists(this.user_extended_data)) return

            await this.api_get({
                url: '/user--extended-data',
                params: undefined,
                commit: true,
                state_property: 'user_extended_data',
            })
        },
        async user_activity(name, attribute, value, domain_action) {
            if (!this.user_is_authenticated || !this.selected_token_json || this.token_type !== 'UserToken') return
            let today = new Date().toUTCString()
            let data = {
                name: name,
                timestamp: today,
            }
            data[attribute] = value

            if (domain_action) data['domain_action'] = domain_action

            await this.api_post({
                url: '/application--user-activities',
                data: data,
            })
        },
        async sign_out(reason = null) {
            if (this.signing_out || !this.selected_token) return

            try {
                this.set_state_property({
                    state_property: 'signing_out',
                    data: true,
                })
                reason = (reason || '').toLowerCase()
                if (!this.token_expired() && reason !== 'unauthorized' && reason !== 'token_expired') {
                    console.log('user signed out manually', this.token_expired())
                    await this.user_activity('top_bar', 'portal', 'logout')
                }
            } catch (e) {
                console.error(e)
            } finally {
                console.log('<<<SIGN OUT>>>')

                this.update_multi_tab_data('user_tokens', null)
                this.reset_user_data()
                this.set_state_properties([
                    {
                        state_property: 'site_refreshes',
                        data: 0,
                    },
                    {
                        state_property: 'refresh_token_signal',
                        data: false,
                    },
                    {
                        state_property: 'loading_application_details',
                        data: false,
                    },
                    {
                        state_property: 'signing_out',
                        data: false,
                    },
                    {
                        state_property: 'user_login_date',
                        data: null,
                    },
                ])
            }
        },
        ...mapActions(['set_state_property', 'set_state_properties']),
    },
}
