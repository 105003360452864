export function init_onetrust(cookie_operations, environment) {
    const is_production = environment === 'production'
    const domain_script = `0193679e-0923-77a2-9130-9dfe78551054${!is_production ? '-test' : ''}`
    const user_settings = localStorage.getItem('user_settings') || {}
    const locale = user_settings?.settings?.ui?.language || 'sv'
    const ot_auto_block_script = document.createElement('script')
    const ot_sdk_stub = document.createElement('script')

    globalThis.OptanonWrapper = () => {
        Object.keys(cookie_operations).forEach((category_id) => {
            const methods = _check_category_cookie_consent(category_id) ? cookie_operations[category_id]?.accepted : cookie_operations[category_id]?.denied
            methods.forEach((method) => method())
        })
    }

    ot_auto_block_script.src = `https://cdn.cookielaw.org/consent/${domain_script}/OtAutoBlock.js`
    ot_sdk_stub.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
    ot_sdk_stub.setAttribute('data-domain-script', domain_script)
    ot_sdk_stub.setAttribute('data-language', locale)

    document.head.appendChild(ot_auto_block_script)
    document.head.appendChild(ot_sdk_stub)
}

function _check_category_cookie_consent(category_id) {
    const consented_categories = (OnetrustActiveGroups || '').split(',')
    return consented_categories.includes(category_id)
}
