<template>
    <v-menu
        v-model="open"
        offset-y
    >
        <template #activator="{ on, attrs }">
            <v-btn
                v-on="on"
                v-bind="attrs"
                icon
            >
                <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
        </template>
        <v-list
            v-if="other_navigation_menu"
            class="pa-0"
            color="#333333"
            dark
            dense
        >
            <v-menu
                v-for="(menu, index) in other_navigation_menu['sub_menu']"
                :key="index"
                offset-x
                open-on-hover
            >
                <template #activator="{ on, attrs }">
                    <v-list-item
                        v-if="menu['list_menu']"
                        v-on="on"
                        class="app-object--clickable"
                        v-bind="attrs"
                    >
                        <v-list-item-title class="d-flex align-center justify-space-between">
                            {{ translate(menu.locale_name) }}
                            <v-icon small>
                                mdi-chevron-right
                            </v-icon>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-else
                        :to="menu"
                        @click.prevent="go_to(menu)"
                        class="nav-list-item"
                    >
                        <v-list-item-title>
                            {{ translate(menu.locale_name) }}
                        </v-list-item-title>
                    </v-list-item>
                </template>
                <v-list
                    class="pa-0"
                    color="#333333"
                    dark
                    dense
                >
                    <v-list-item
                        v-for="(sub_menu, sub_index) in menu['list_menu']"
                        :key="sub_index"
                        :to="sub_menu"
                        @click.prevent="go_to(sub_menu)"
                        class="nav-list-item"
                    >
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ translate(sub_menu.locale_name) }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-list>
    </v-menu>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import locale_mixin from '@/mixins/common/locale_mixin'

export default {
    name: 'TheHeaderOtherMenu',
    props: {
        other_navigation_menu: {
            type: Object,
            default: () => {},
        },
    },
    mixins: [locale_mixin],
    components: {},
    data() {
        return {
            open: false,
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        go_to(route) {
            this.$emit('reset_current_tab')
            this.open = false
            if (this.$router.resolve(route).href !== this.$router.resolve(this.$route).href) {
                this.$router.push(route)
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style lang="sass" scoped>
::v-deep a.v-list-item--link.v-list-item--active.nav-list-item
    color: #fff !important
</style>
