<template>
    <div>
        <component
            v-if="internal_step.component"
            v-model="internal_value"
            :error.sync="internal_error"
            :step_completed.sync="internal_step_completed"
            :step_disabled.sync="internal_step_disabled"
            :step_props.sync="internal_step_props"
            :step_showing="step_showing"
            :step_shown="step_shown"
            :is="internal_step.component"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin.js'

export default {
    name: 'AppStepperNewStep',
    components: {},
    mixins: [common_mixin],
    props: {
        index: Number,
        steps: Array,
        value: Object,
        error: Object,
        completed_steps: Array,
        disabled_steps: Array,
        step_showing: Boolean,
        step_shown: Boolean,
    },
    data() {
        return {}
    },
    computed: {
        internal_error: {
            get() {
                return this.error
            },
            set(val) {
                this.$emit('update:error', val)
            },
        },
        internal_step_props: {
            get() {
                return this.deep_get(this.internal_step, 'props', false)
            },
            set(value) {
                this.set_nested_property(this.internal_step, 'props', value)
            },
        },
        internal_step: {
            get() {
                return this.deep_get(this.internal_steps, `${this.index}`, false)
            },
            set(value) {
                this.set_nested_property(this.internal_steps, `${this.index}`, value)
            },
        },
        internal_steps: {
            get() {
                return this.steps
            },
            set(value) {
                this.$emit('update:steps', value)
            },
        },
        internal_step_completed: {
            get() {
                return this.deep_get(this.internal_completed_steps, `${this.index}`, false)
            },
            set(value) {
                this.set_nested_property(this.internal_completed_steps, `${this.index}`, value)
            },
        },
        internal_completed_steps: {
            get() {
                return this.completed_steps
            },
            set(value) {
                this.$emit('update:completed_steps', value)
            },
        },
        internal_step_disabled: {
            get() {
                return this.deep_get(this.internal_disabled_steps, `${this.index}`, false)
            },
            set(value) {
                this.set_nested_property(this.internal_disabled_steps, `${this.index}`, value)
            },
        },
        internal_disabled_steps: {
            get() {
                return this.disabled_steps
            },
            set(value) {
                this.$emit('update:disabled_steps', value)
            },
        },
        internal_value: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped></style>
