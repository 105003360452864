<template>
    <section>
        <ContentTitle
            :title="translate('common.sign')"
            pb="3"
        />

        <v-card-text class="pa-0 pb-6">
            {{ translate('common.self_registration_last_step_description') }}
        </v-card-text>

        <div class="text-h6 font-weight-medium pb-3">{{ translate('common.your_account') }}</div>

        <v-row
            v-for="(summary_row, index) of summary_data"
            :key="`summary_row_${index}`"
            class="pb-3 pl-4"
            no-gutters
        >
            <v-col cols="4">
                <div
                    class="subtitle-2 font-weight-bold"
                    style="white-space: nowrap"
                >
                    {{ translate(summary_row.label) }}
                </div>
            </v-col>

            <v-col>
                <div v-if="Array.isArray(summary_row.value)">
                    <div
                        v-for="(item, index) in summary_row.value"
                        :key="`summary_row_${index}_${index}`"
                    >
                        {{ item }}
                    </div>
                </div>

                <div v-else>
                    {{ summary_row.value }}
                </div>
            </v-col>
        </v-row>

        <v-card
            style="border-radius: 0"
            class="my-3"
            color="brand_secondary"
            flat
        >
            <v-row
                class="pl-4 py-5"
                no-gutters
            >
                <v-col
                    class="d-flex align-center"
                    cols="4"
                >
                    <h3>{{ translate('common.your_agreement') }}:</h3>
                </v-col>

                <v-col class="d-flex align-center">
                    <MediaFileDialog
                        v-if="generic_agreement_document_url"
                        :media_url="generic_agreement_document_url"
                        :media_file_data="generic_agreement_document_file"
                    >
                        <template #activator="{ open_in_app }">
                            <v-btn
                                @click="open_in_app"
                                class="pl-2"
                                elevation="0"
                                outlined
                            >
                                <v-icon
                                    class="mr-2"
                                    color="primary"
                                >
                                    mdi-file-pdf-box
                                </v-icon>
                                {{ generic_agreement_document_file.filename }}
                            </v-btn>
                        </template>
                    </MediaFileDialog>
                </v-col>
            </v-row>
        </v-card>
    </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { media_file_mixin } from '@/mixins/media_file'
import common_mixin from '@/mixins/common/mixin'
import form_mixin from '@/mixins/form/mixin.js'
import ContentTitle from '@/components/content_title/Index.vue'
import MediaFileDialog from '@/components/media_file_dialog'

export default {
    name: 'SelfRegistrationRegistrationStepsSigning',
    components: {
        ContentTitle,
        MediaFileDialog,
    },
    mixins: [media_file_mixin, common_mixin, form_mixin],
    props: {
        value: Object,
        step_props: Object,
        step_completed: Boolean,
        step_disabled: Boolean,
        step_showing: Boolean,
        step_shown: Boolean,
    },
    data() {
        return {
            generic_agreement_document_url: null,
        }
    },
    computed: {
        summary_data() {
            return [
                {
                    label: 'common.invoice_issuer',
                    value: this.company?.nm || '',
                },
                {
                    label: 'common.account_owner',
                    value: this.selected_token_json?.nm || '',
                },
                {
                    label: this.internal_value.bankgiro ? 'common.bankgiro' : 'common.plusgiro',
                    value: this.internal_value.bankgiro || this.internal_value?.plusgiro || '',
                },
                {
                    label: 'common.invoice_refers_to',
                    value: this.internal_value.debt_description || '',
                },
                {
                    label: 'common.mobile_number',
                    value: this.internal_value.mobile_number || '',
                },
                {
                    label: 'common.email',
                    value: this.internal_value.email || '',
                },
                {
                    label: 'common.address',
                    value: [
                        this.internal_value.address?.address || '',
                        `${this.internal_value.address?.zip_code || ''} ${this.internal_value.address?.city || ''} `,
                        this.internal_value.address?.country || '',
                    ],
                },
            ]
        },
        company() {
            return this.selected_token_json?.co_list.find((company) => company.no === this.deep_get(this.internal_value, 'organization_no'))
        },
        self_registration_template() {
            return this.selected_token_json?.srt_list.find((template) => template._id === this.deep_get(this.internal_value, 'self_registration_template'))
        },
        generic_agreement_document_file() {
            return {
                id: this.self_registration_template?.doc_id,
                filename: this.translate(this.self_registration_template?.t_loc),
                content_type: 'application/pdf',
            }
        },
        internal_value: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        internal_step_props: {
            get() {
                return this.step_props
            },
            set(value) {
                this.$emit('update:step_props', value)
            },
        },
        internal_step_completed: {
            get() {
                return this.step_completed
            },
            set(value) {
                this.$emit('update:step_completed', value)
            },
        },
        internal_step_disabled: {
            get() {
                return this.step_disabled
            },
            set(value) {
                this.$emit('update:step_disabled', value)
            },
        },
        ...mapGetters(['selected_token_json']),
        ...mapState([]),
    },
    watch: {
        step_showing() {
            this.ensure_completion()
        },
        async self_registration_template() {
            await this.ensure_generic_agreement_document_url()
        },
    },
    methods: {
        ensure_completion() {
            if (this.step_showing && !this.internal_step_completed) {
                this.internal_step_completed = true
            }
        },
        async ensure_generic_agreement_document_url() {
            if (this.self_registration_template?.doc_id) {
                this.generic_agreement_document_url = await this.get_media_file_url(
                    'self-registration--templates',
                    this.self_registration_template._id,
                    this.self_registration_template?.doc_id
                )
            }
        },
        async get_media_file_url(resource, resource_id, bucket_id) {
            const url = `/media--url/${resource}/${resource_id}/${bucket_id}`
            let response = null

            await this.api_call_wrapper_v2(
                async () => {
                    response = await this.api_get({ url })
                },
                {
                    use_snackbar: false,
                }
            )

            if (response && response.status === 200) {
                return response.data.url
            }

            return null
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    async mounted() {
        this.ensure_completion()
        await this.ensure_generic_agreement_document_url()
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped></style>
