import { remove_cookies, get_environment } from '@/plugins/cookie_policy'

let is_datadog_initialized = false

export function init_datadog() {
    if (!['production', 'test'].includes(get_environment())) {
        return
    }

    if (!is_datadog_initialized) {
        const script = document.createElement('script')
        script.async = true
        script.src = 'https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js'
        script.onload = () => {
            window.DD_RUM.init({
                applicationId: '6eec4f3b-be31-4fff-8624-5414065c4ea6',
                clientToken: 'pub3d5737629bb56110593aa4f8f9a1c95d',
                site: 'datadoghq.eu',
                service: 'ada-ui',
                env: 'production',
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: 'allow',
                trackingConsent: 'granted',
            })
            is_datadog_initialized = true
        }

        document.head.appendChild(script)
    } else {
        window.DD_RUM.setTrackingConsent('granted')
        window.DD_RUM.startSessionReplayRecording()
    }
}

export function remove_datadog() {
    if (!['production', 'test'].includes(get_environment())) {
        return
    }

    if (window.DD_RUM) {
        window.DD_RUM.stopSession()
        window.DD_RUM.stopSessionReplayRecording()
        window.DD_RUM.setTrackingConsent('not-granted')
    }

    const cookies = ['_dd_s']
    remove_cookies(cookies)
}
