<template>
    <v-app v-if="user_is_authenticated && custom_access === 'partner_self_registration'">
        <AdaPreAuthentication />
    </v-app>
    <v-app
        v-else-if="user_is_authenticated || unauthenticated_route_whitelist.includes($route.name)"
        :class="{ hide_overflow: right_navigation_drawer }"
    >
        <template v-if="!hide_header_footer_and_drawers">
            <TheHeader
                @open_left_navigation_drawer="left_navigation_drawer = true"
                @open_right_navigation_drawer="right_navigation_drawer = true"
                @right_navigation_component="right_navigation_component = $event"
            />

            <TheLeftNavigationDrawer v-model="left_navigation_drawer" />
            <TheRightNavigationDrawer
                v-model="right_navigation_drawer"
                :component="right_navigation_component"
            />
        </template>

        <TheSnackbars />
        <TheUnsavedChangesDialog />
        <v-progress-circular
            v-if="!exists(swagger_paths) || !exists(swagger_x_data)"
            id="dialog_spinner"
            class="app-object--absolute-center app-layout--level-10"
            color="primary"
            indeterminate
        />
        <v-main
            v-else
        >
            <v-container
                class="pa-6"
                fluid
            >
                <v-row
                    align="center"
                    justify="center"
                    no-gutters
                >
                    <v-col
                        lg="11"
                        md="12"
                        sm="12"
                        xl="11"
                        xs="12"
                    >
                        <TheGreetingPopup />
                        <router-view
                            :key="selected_token_json ? selected_token_json.token_id : null"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-main>

        <TheWootric v-if="user_is_authenticated && user_data_loaded" />
    </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import common_mixin from '@/mixins/common/mixin'

import AdaPreAuthentication from '@/components/the_pre_authentication/Index'
import TheGreetingPopup from '@/components/the_greeting_popup/Index'
import TheLeftNavigationDrawer from '@/components/the_left_navigation_drawer/Index'
import TheHeader from '@/components/the_header/index.vue'
import TheRightNavigationDrawer from '@/components/the_right_navigation_drawer/index.vue'
import TheWootric from '@/components/the_wootric/Index.vue'
import TheSnackbars from '@/components/the_snackbars'
import debounce from 'lodash/debounce'
import { multi_tab_state_mixin } from '@/mixins/multi_tab_state/mixin'
import TheUnsavedChangesDialog from '@/components/the_unsaved_changes_dialog/index.vue'

export default {
    name: 'App',
    components: {
        TheUnsavedChangesDialog,
        TheSnackbars,
        TheWootric,
        TheRightNavigationDrawer,
        TheHeader,
        TheLeftNavigationDrawer,
        TheGreetingPopup,
        AdaPreAuthentication,
    },
    mixins: [common_mixin, multi_tab_state_mixin],
    props: [],
    data() {
        return {
            left_navigation_drawer: false,
            right_navigation_drawer: false,
            right_navigation_component: null,
            notification_interval_id: null,
            debounce_save_user_settings_updates: debounce(async () => {
                await this.save_user_settings_updates()
            }, 1000),
            hide_header_footer_and_drawers_on_routes: ['Login', 'SelfRegistration'],
            authenticated_route_blacklist: ['Login', 'SelfRegistration'],
            unauthenticated_route_whitelist: ['Login', 'SelfRegistration', 'Open'],
        }
    },
    computed: {
        forced_route() {
            if (!this.multi_tab_data_loaded) {
                return null
            }

            if (!this.user_is_authenticated) {
                if (this.unauthenticated_route_whitelist.includes(this.$route.name)) {
                    return this.$route.name
                }

                if (!['Login', 'Home'].includes(this.$route.name)) {
                    this.set_state_property({
                        state_property: 'redirect_url_on_login_success',
                        data: window.location.href,
                    })
                }

                return 'Login'
            }

            // If/when partner self registration is moved, use a more generic solution for custom access here
            if (this.custom_access === 'self_registration') {
                return 'SelfRegistration'
            }

            if (this.authenticated_route_blacklist.includes(this.$route.name)) {
                return 'Home'
            }

            return null
        },
        hide_header_footer_and_drawers() {
            return this.hide_header_footer_and_drawers_on_routes.includes(this.$route.name) || !this.user_is_authenticated
        },
        view_context_and_ui_structure() {
            if (Object.keys(this.view_context).length !== 0 && Object.keys(this.ui_structure).length !== 0) {
                return {
                    view_context: this.view_context,
                    ui_structure: this.ui_structure,
                }
            }

            return {}
        },
        ...mapGetters({
            user_is_authenticated: 'user_is_authenticated',
            token_type: 'token_type',
            custom_access: 'custom_access',
            selected_token_json: 'selected_token_json',
            user_extended_data_loaded: 'user_extended_data_loaded',
            user_data_loaded: 'user_data_loaded',
            selected_token: 'selected_token',
            ui_structure: 'ui_structure',
        }),
        ...mapState({
            site_refreshes: (state) => state.site_refreshes,
            user_tokens: (state) => state.user_tokens,
            application_details: (state) => state.application_details,
            token_has_expired: (state) => state.token_has_expired,
            swagger_paths: (state) => state.swagger.paths,
            swagger_x_data: (state) => state.swagger.x_data,
            schemas_in_memory: (state) => state.swagger.schemas_in_memory,
            multi_tab_data_loaded: (state) => state.multi_tab_data_loaded,
            redirect_url_on_login_success: (state) => state.redirect_url_on_login_success,
            view_context: (state) => state.view_context,
        }),
    },
    watch: {
        'user_tokens.selected_token': {
            async handler(val, old_val) {
                if (Number.isInteger(val) && Number.isInteger(old_val)) {
                    this.reset_user_data()
                    if (this.$router.currentRoute.name !== 'Home') {
                        await this.$router.push({ name: 'Home' })
                    }
                }

                if (Number.isInteger(val)) {
                    await this.load_user_data()
                }
            },
            immediate: true,
        },
        '$route'(to) {
            let activity_name = to.params && to.params.definition ? to.params.definition : 'navigation'
            this.user_activity(activity_name, 'route', to.name)
        },
        'forced_route': {
            handler() {
                this.handle_forced_route()
            },
            immediate: true,
        },
        'token_has_expired': {
            handler(val) {
                if (val) {
                    this.debounce_refresh_token()
                }
            },
            immediate: true,
        },
        'swagger_paths': {
            handler(val) {
                if (this.exists(val)) return

                this.load_swagger_paths()
            },
            immediate: true,
        },
        'swagger_x_data': {
            handler(val) {
                if (this.exists(val)) return

                this.load_swagger_x_data()
            },
            immediate: true,
        },
        'schemas_in_memory': {
            handler(val) {
                if (this.exists(val)) return

                this.get_swagger_schema('access--groups')
            },
            immediate: true,
        },
        'user_settings.settings.ui.theme': {
            handler(val) {
                if (val) {
                    this.set_theme(val)
                }
            },
            immediate: true,
        },
        'user_settings.settings.ui.language': {
            handler(val) {
                if (val) {
                    this.set_language(val)
                }
            },
            immediate: true,
        },
        'user_settings_updates': {
            handler(val) {
                if (val.length) {
                    this.update_multi_tab_data('user_settings', this.user_settings) // store changes in store
                    this.debounce_save_user_settings_updates()
                }
            },
            immediate: true,
        },
        'user_settings._etag': {
            handler(val, old_val) {
                if (val === old_val) {
                    return
                }
                this.update_multi_tab_data('user_settings', this.user_settings) // update _etag
            },
        },
        async view_context_and_ui_structure({ view_context, ui_structure }) {
            if (view_context === undefined || ui_structure === undefined) {
                return
            }

            document.title = await this.get_page_title(
                view_context.resource,
                view_context.resource_id,
                ui_structure[view_context.resource],
            )
        },
    },
    methods: {
        async unregister_service_worker() {
            const registrations = await navigator.serviceWorker.getRegistrations()
            for (const registration of registrations) {
                await registration.unregister()
            }
        },
        site_refreshed() {
            this.set_state_property({
                state_property: 'site_refreshes',
                data: this.site_refreshes + 1,
            })
        },
        handle_forced_route() {
            if (this.forced_route && this.$route.name !== this.forced_route) {
                this.$router.replace({ name: this.forced_route, query: this.$route.query })
            }
        },
        ...mapActions(['set_state_property']),
    },
    beforeCreate() {
    },
    async created() {
        this.load_multi_tab_data()
        window.onstorage = this.handle_tab_synchronization

        if (this.user_is_authenticated) {
            this.site_refreshed()
        }

        await this.get_application_details()
    },
    beforeMount() {
        this.unregister_service_worker()
    },
    async mounted() {
        // Use to calculate the KB size of entire or selected  keys in local storage
        // console.log('local storage size:',this.local_storage_size(['permanent_storage']))
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>

<style lang="sass">
.hide_overflow
    overflow: hidden
    max-height: 100vh
</style>
