<template>
    <v-app class="self-registration--container">
        <div
            v-if="custom_access === 'self_registration'"
            class="self-registration__no-bg pa-4"
        >
            <Registration />
        </div>

        <div
            v-else
            class="self-registration__bg pa-4"
        >
            <Identification />
        </div>
    </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'
import Identification from './Identification'
import Registration from './registration'

export default {
    name: 'SelfRegistration',
    components: { Identification, Registration },
    mixins: [common_mixin],
    props: {},
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            custom_access: 'custom_access',
        }),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped>
.self-registration--container {
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
}
</style>
