import { init_onetrust } from '@/plugins/onetrust'
import { init_datadog, remove_datadog } from '@/plugins/datadog'
import { remove_wootric } from '@/plugins/wootric'

const cookie_categories = {
    analytics: 'C0002',
}

const cookie_operations = {
    [cookie_categories.analytics]: {
        accepted: [init_datadog],
        denied: [remove_datadog, remove_wootric],
    },
}

export function init_cookie_policy() {
    init_onetrust(cookie_operations, get_environment())
}

/* Utils */
export function remove_cookies(cookies) {
    const root_domain = location.hostname.split('.').slice(-2).join('.')

    cookies.forEach((cookie) => {
        document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${root_domain}`
        document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${root_domain}`
        document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${location.hostname}`
        document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${location.hostname}`
    })
}

export function get_environment() {
    const domains = {
        'app-test.amili.se': 'test',
        'app-stage.amili.se': 'stage',
        'app-sandbox.amili.se': 'sandbox',
        'app.amili.se': 'production',
    }

    return domains[location.host] || 'development'
}
