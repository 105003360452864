<template>
    <v-form v-model="form_valid">
        <ContentTitle :title="translate('common.contact')" />
        <ItemDisplayInput
            v-for="attribute of ['mobile_number', 'email']"
            :key="`contact_${attribute}`"
            v-model="internal_value"
            :custom_label="translate(`common.${attribute}`)"
            :custom_property="vm[`${attribute}_custom_property`]"
            :attribute="`${attribute}`"
        />

        <AddressSelector
            v-model="internal_value_address"
            :addresses="company_addresses"
        />
    </v-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'
import form_mixin from '@/mixins/form/mixin.js'
import ContentTitle from '@/components/content_title/Index.vue'
import ItemDisplayInput from '@/components/item_display_input/'
import AddressSelector from './AddressSelector.vue'

export default {
    name: 'SelfRegistrationRegistrationStepsContact',
    components: {
        ItemDisplayInput,
        ContentTitle,
        AddressSelector,
    },
    mixins: [common_mixin, form_mixin],
    props: {
        value: Object,
        step_props: Object,
        step_completed: Boolean,
        step_disabled: Boolean,
        step_showing: Boolean,
        step_shown: Boolean,
    },
    data() {
        return {
            vm: this,
            form_valid: false,
        }
    },
    computed: {
        is_completed() {
            return Boolean(this.form_valid && ['address', 'zip_code', 'city'].every((prop) => this.internal_value_address[prop] && this.internal_value_address[prop].length))
        },
        mobile_number_custom_property() {
            return {
                type: 'string',
                is_required: true,
                pattern: /^\+46\d{7,9}$/,
            }
        },
        email_custom_property() {
            return {
                type: 'string',
                is_required: true,
                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            }
        },
        company_addresses() {
            return this.selected_token_json?.co_list
                .filter((company) => company.no === this.internal_value.organization_no)
                .map((company) =>
                    company.adr_list.map((address) => ({
                        address: address.adr,
                        zip_code: address.zip,
                        city: address.city,
                        country: address.cntry,
                    }))
                )
                .flat()
        },
        internal_value_address: {
            get() {
                return this.deep_get(this.internal_value, 'address', false) || {}
            },
            set(value) {
                this.set_nested_property(this.internal_value, 'address', value)
            },
        },
        internal_value: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        internal_step_props: {
            get() {
                return this.step_props
            },
            set(value) {
                this.$emit('update:step_props', value)
            },
        },
        internal_step_completed: {
            get() {
                return this.step_completed
            },
            set(value) {
                this.$emit('update:step_completed', value)
            },
        },
        internal_step_disabled: {
            get() {
                return this.step_disabled
            },
            set(value) {
                this.$emit('update:step_disabled', value)
            },
        },
        ...mapGetters(['selected_token_json']),
        ...mapState([]),
    },
    watch: {
        is_completed(boolean) {
            this.internal_step_completed = boolean
        },
    },
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped></style>
