<template>
    <section
        v-if="!shown_items.length"
        class="flex-grow-1 d-flex align-center justify-center"
    >
        <v-progress-circular
            v-if="loading"
            color="brand_primary"
            indeterminate
        />
        <v-fade-transition>
            <div
                v-if="!configurable"
                v-show="!loading"
                class="subtitle-2 font-weight-medium app-opacity--level-5"
            >
                {{ translate('common.todo_empty_state') }}
            </div>
            <div
                v-else
                v-show="!loading"
                class="d-flex flex-column align-center justify-center"
            >
                <div class="subtitle-2 font-weight-medium app-opacity--level-5">
                    {{ translate('common.todo_empty_state_configurable') }}
                </div>

                <v-btn
                    v-show="!loading"
                    :disabled="!has_patch_access('creditor--settings')"
                    @click="$emit('adjust_activities')"
                    class="mt-2"
                    text
                    outlined
                    small
                >
                    {{ translate('common.adjust_creditor_notifications') }}
                    <v-icon small> mdi-tune-vertical-variant</v-icon>
                </v-btn>
            </div>
        </v-fade-transition>
    </section>
    <section
        v-else
        ref="list"
        :class="animation_completed ? 'overflow-auto' : 'overflow-hidden'"
        class="flex-grow-1"
    >
        <TheDashboardTodoListItem
            v-for="(item, index) in shown_items"
            :key="`${filter.title}-${item._id}`"
            :index="index"
            :item="item"
            :intersection_observer="intersection_observer"
            :loading="loading"
            @completed="animation_completed = true"
            @hide="() => $emit('hide', item)"
        />
        <div
            v-show="animation_completed && show_more_available"
            ref="load_more_btn"
            class="px-6 pb-4"
        >
            <v-divider class="mb-4" />
            <div class="d-flex justify-center">
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    @click="$emit('load_more')"
                    outlined
                >
                    {{ translate('common.show_more') }}
                </v-btn>
            </div>
        </div>
    </section>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import locale_mixin from '@/mixins/common/locale_mixin'
import access_mixin from '@/mixins/common/access_mixin'
import TheDashboardTodoListItem from '@/components/the_dashboard/todo/TodoListItem.vue'
import { nextTick } from 'vue'

export default {
    name: 'TheDashboardTodoList',
    props: {
        loading: {
            type: Boolean,
        },
        filter: {
            type: Object,
        },
        filter_items: {
            type: Array,
        },
        configurable: {
            type: Boolean,
        },
    },
    mixins: [locale_mixin, access_mixin],
    components: { TheDashboardTodoListItem },
    data() {
        return {
            animation_completed: false,
            intersection_observer: undefined,
            load_more_intersection_observer: undefined,
            notification_read_timers: {},
        }
    },
    computed: {
        show_more_available() {
            return this.filter.meta['max_results'] * this.filter.meta['page'] < this.filter.meta['total']
        },
        show_more_button_shown() {
            return this.shown_items.length && this.animation_completed && this.show_more_available
        },
        shown_items() {
            return this.filter_items.filter((notification) => !notification.hidden_by_creditor && !notification.hidden_by_system)
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        filter() {
            this.animation_completed = false
            if (this.$refs.list) {
                this.$refs.list.scroll({ top: 0 })
            }
        },
        show_more_button_shown() {
            nextTick(() => {
                if (this.$refs.load_more_btn) {
                    this.load_more_intersection_observer?.observe(this.$refs.load_more_btn)
                }
            })
        },
    },
    methods: {
        on_notification_visibility_change(/** @type {IntersectionObserverEntry[]} */ entries) {
            if (!this.has_patch_access('creditor--notifications')) return

            for (const entry of entries) {
                const notification_id = entry.target.getAttribute('data-notification-id')
                const notification = this.filter_items.find((item) => item._id === notification_id)
                if (notification == null) continue

                if (entry.isIntersecting) {
                    this.$set(
                        this.notification_read_timers,
                        notification_id,
                        setTimeout(() => {
                            this.$emit('read', notification)
                        }, 500)
                    )
                } else if (this.notification_read_timers[notification_id]) {
                    clearTimeout(this.notification_read_timers[notification_id])
                    this.$set(this.notification_read_timers, notification_id, null)
                }
            }
        },
        on_close_to_load_more(/** @type {IntersectionObserverEntry[]} */ entries) {
            for (const entry of entries) {
                if (entry.isIntersecting && this.show_more_available && !this.loading) {
                    this.$emit('load_more')
                }
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        this.intersection_observer = new IntersectionObserver(this.on_notification_visibility_change.bind(this), {
            root: this.$el,
            threshold: 0.5,
        })
        this.load_more_intersection_observer = new IntersectionObserver(this.on_close_to_load_more.bind(this), {
            root: this.$el,
            // each notification is ~90px
            rootMargin: '180px 0px 0px 0px',
        })
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {
        this.intersection_observer?.disconnect()
        this.intersection_observer = undefined
        this.load_more_intersection_observer?.disconnect()
        this.load_more_intersection_observer = undefined
    },
    destroyed() {},
}
</script>
<style scoped></style>
