<template>
    <v-stepper-header v-if="steps">
        <Step
            v-for="(step, index) of steps"
            :key="`stepper_progress_bar_step_${index}`"
            :completed_steps="completed_steps"
            :current_step_number.sync="internal_current_step_number"
            :disabled_steps="disabled_steps"
            :index="index"
            :show_divider="index !== steps.length - 1"
            :step="step"
            :stepper_disabled="stepper_disabled"
        />
    </v-stepper-header>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin.js'
import Step from './Step.vue'

export default {
    name: 'AppStepperNewProgressBar',
    components: { Step },
    mixins: [common_mixin],
    props: {
        steps: Array,
        current_step_number: {
            type: [String, Number],
        },
        completed_steps: Array,
        disabled_steps: Array,
        stepper_disabled: Boolean,
    },
    data() {
        return {}
    },
    computed: {
        internal_current_step_number: {
            get() {
                return this.current_step_number
            },
            set(value) {
                this.$emit('update:current_step_number', value)
            },
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped>
.v-stepper__header {
    box-shadow: none;
    height: auto;
}
</style>
