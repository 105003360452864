<template>
    <v-layout
        :class="{
            'opacity-50': !can_go_to_step && step_number !== internal_current_step_number,
        }"
        class="stepper-progress-bar-step--wrapper"
    >
        <v-stepper-step
            v-if="step"
            :class="{
                'app-object--clickable': can_go_to_step,
            }"
            :complete="completed_steps?.[index]"
            :step="step_number"
            @click="go_to_step(step_number)"
        >
            {{ translate(step?.title || 'common.missing') }}
        </v-stepper-step>

        <v-divider v-if="show_divider" />
    </v-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin.js'

export default {
    name: 'AppStepperNewProgressBarStep',
    components: {},
    mixins: [common_mixin],
    props: {
        index: Number,
        step: Object,
        current_step_number: {
            type: [String, Number],
        },
        show_divider: {
            type: Boolean,
            value: false,
        },
        completed_steps: Array,
        disabled_steps: Array,
        stepper_disabled: Boolean,
    },
    data() {
        return {}
    },
    computed: {
        internal_current_step_number: {
            get() {
                return this.current_step_number
            },
            set(value) {
                this.$emit('update:current_step_number', value)
            },
        },
        step_number() {
            return this.index + 1
        },
        previous_steps_completed() {
            if (this.index === 0) return true

            for (let i = this.index - 1; i >= 0; i--) {
                if (!this.completed_steps[i]) {
                    return false
                }
            }
            return true
        },
        can_go_to_step() {
            return !!(
                this.completed_steps?.[this.index] &&
                !(this.disabled_steps?.[this.index] || this.stepper_disabled) &&
                this.previous_steps_completed &&
                this.current_step_number !== this.step_number
            )
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        go_to_step(step_number) {
            if (this.can_go_to_step) {
                this.internal_current_step_number = step_number
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped>
.stepper-progress-bar-step--wrapper:first-child > .v-stepper__step {
    padding-left: 0;
}

.stepper-progress-bar-step--wrapper:last-child > .v-stepper__step {
    padding-right: 0;
}

.stepper-progress-bar-step--wrapper:last-child {
    flex-grow: 0;
}

.opacity-50 {
    opacity: 0.5;
}
</style>
