import { mapActions, mapGetters, mapState } from 'vuex'

export const media_file_mixin = {
    data() {
        return {}
    },
    mixins: [],
    computed: {
        ...mapGetters([]),
        ...mapState({}),
    },
    methods: {
        file_to_media_file_data(file) {
            return {
                id: null,
                filename: file.name,
                content_type: file.type,
                size: file.size,
            }
        },
        file_to_media_url(file) {
            if (file) {
                return URL.createObjectURL(file)
            }
            return null
        },
        media_file_procedure_origin(attribute, is_array) {
            if (is_array) {
                return `${attribute}.document`
            }

            return attribute
        },
        ...mapActions({}),
    },
}
