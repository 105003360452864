<template>
    <v-container class="fill-height">
        <v-row class="fill-height flex-column justify-center">
            <v-col
                xl="6"
                lg="8"
                sm="10"
                xs="12"
                offset-xl="3"
                offset-lg="2"
                offset-sm="1"
            >
                <ContentTitle
                    :title="`${translate('common.register_an_account_in')} Amili Ease`"
                    style="padding-bottom: 0 !important"
                />

                <AppStepperNew
                    v-model="self_registration"
                    :disabled="disabled"
                    :error.sync="error"
                    :steps.sync="steps"
                    :initial_step_number="initial_step_number"
                    @step_changed="persist_self_registration_data"
                />
            </v-col>
        </v-row>

        <MobileBankIDDialog
            v-model="show_bankid_dialog"
            :signing_data="signing_data"
            :signing_user_visible_data="signing_user_visible_data"
            @completed="post_registration"
            create_signing_token_endpoint="/self-registration/mobile-bankid-sign/create-signing-token"
            poll_endpoint="/self-registration/mobile-bankid-sign/{signing_token}"
            signing
        />
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'
/* Generic components */
import AppStepperNew from '@/components/app_stepper_new'
import ContentTitle from '@/components/content_title/Index.vue'
import MobileBankIDDialog from '@/components/mobile_bankid_dialog'
/* Steps */
import StepsAccount from './steps/account'
import StepsContact from './steps/contact'
import StepsAgreement from './steps/agreement'
import StepsSigning from './steps/signing'

export default {
    name: 'SelfRegistrationRegistration',
    components: {
        ContentTitle,
        AppStepperNew,
        MobileBankIDDialog,
    },
    mixins: [common_mixin],
    props: {},
    data() {
        return {
            show_bankid_dialog: false,
            self_registration: {},
            initial_step_number: 1,
            disabled: false,
            error: {},
            steps: [
                {
                    title: 'common.your_account',
                    component: StepsAccount,
                    props: {},
                    next: {
                        label: 'common.next',
                        color: 'primary',
                        callback: (payload) => {},
                    },
                },
                {
                    title: 'common.contact',
                    component: StepsContact,
                    props: {},
                    back: {
                        label: 'common.back',
                        color: 'primary',
                        callback: (payload) => {},
                    },
                    next: {
                        label: 'common.next',
                        color: 'primary',
                        callback: (payload) => {},
                    },
                },
                {
                    title: 'common.agreement',
                    component: StepsAgreement,
                    props: {},
                    back: {
                        label: 'common.back',
                        color: 'primary',
                        callback: (payload) => {},
                    },
                    next: {
                        label: 'common.next',
                        color: 'primary',
                        callback: (payload) => {},
                    },
                },
                {
                    title: 'common.sign',
                    component: StepsSigning,
                    props: {},
                    back: {
                        label: 'common.back',
                        color: 'primary',
                        callback: (payload) => {},
                    },
                    next: {
                        label: 'common.sign',
                        color: 'primary',
                        callback: (payload) => {
                            this.show_bankid_dialog = true
                        },
                    },
                },
            ],
        }
    },
    computed: {
        self_registration_template() {
            return this.selected_token_json?.srt_list.find((template) => template._id === this.self_registration.self_registration_template)
        },
        signing_data() {
            return this.self_registration
        },
        signing_user_visible_data() {
            return this.self_registration_template?.t_loc ? this.translate(this.self_registration_template.t_loc) : ''
        },
        ...mapGetters([]),
        ...mapState(['persisted_self_registration_data']),
    },
    watch: {},
    methods: {
        persist_self_registration_data(payload) {
            const data = { step_number: payload.current_step_number, self_registration: this.self_registration }
            this.set_state_property({ state_property: 'persisted_self_registration_data', data })
        },
        ensure_persisted_self_registration_data() {
            if (this.persisted_self_registration_data) {
                this.self_registration = this.persisted_self_registration_data.self_registration
                this.initial_step_number = this.persisted_self_registration_data.step_number
            }
        },
        async post_registration(data) {
            const { signing_token } = data
            let response = null

            await this.api_call_wrapper_v2(
                async () => {
                    response = await this.api_post({
                        url: '/self-registration/register',
                        token: this.pre_auth_token,
                        data: { signing_token },
                    })
                },
                {
                    use_snackbar: false,
                }
            )

            if (response && response.status === 200) {
                const { token_list } = response.data

                if (token_list && token_list.length) {
                    this.update_multi_tab_data('user_tokens', {
                        token_list: token_list,
                        selected_token: 0,
                    })

                    this.set_state_property({ state_property: 'persisted_self_registration_data', data: null })
                    await this.load_user_data()
                }
            }
        },
        ...mapActions(['set_state_property']),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
        this.ensure_persisted_self_registration_data()
    },
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped>
.fill-height {
    height: 100%;
}

pre {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    padding: 10px;
}
</style>
