<template>
    <section style="grid-area: statistics">
        <v-expand-transition>
            <div
                v-show="show"
                class="mb-4 d-flex justify-space-between align-center"
            >
                <span class="text-h5">{{ translate('common.statistics') }}</span>

                <v-btn
                    v-if="creditor_id"
                    :disabled="!has_patch_access('creditor--settings')"
                    @click="show_adjust_statistics()"
                    text
                >
                    <v-icon left>
                        mdi-tune-vertical-variant
                    </v-icon>
                    {{ translate('common.adjust_statistics') }}
                </v-btn>
            </div>
        </v-expand-transition>
        <section
            :style="`--states: ${enabled_states.length};`"
            class="grid"
        >
            <v-card
                v-for="key in enabled_states"
                :key="key"
                :loading="states[key] === null"
                class="pa-6"
                min-height="126"
                outlined
            >
                <template #progress>
                    <v-progress-linear
                        color="brand_primary"
                        absolute
                        indeterminate
                        top
                    />
                </template>
                <div class="subtitle-2 font-weight-medium app-opacity--level-7 app-text--overflow-hidden-ellipsis">
                    {{ translate(`common.${key}`) }}
                </div>
                <v-fade-transition>
                    <section
                        v-show="states[key] !== null"
                        class="d-flex align-center flex-wrap mt-5"
                        style="column-gap: 20px; row-gap: 10px"
                    >
                        <div class="count">
                            <div class="number mr-2">
                                {{ new Intl.NumberFormat(current_language).format(states[key]) }}
                            </div>
                            <div
                                v-if="current_language === 'sv'"
                                class="st"
                            >
                                st
                            </div>
                        </div>
                        <TheDashboardStatisticsTrend
                            :last_thirty_days="last_thirty_days[key]"
                            :previous_thirty_days="previous_thirty_days[key]"
                        />
                    </section>
                </v-fade-transition>
            </v-card>
        </section>

        <v-dialog
            v-model="show_dialog"
            width="500"
            persistent
        >
            <v-card :loading="saving">
                <v-card-title class="d-flex justify-space-between align-center">
                    <div class="text-h5">
                        {{ translate('common.adjust_statistics') }}
                    </div>
                    <v-btn
                        :disabled="saving"
                        @click="show_dialog = false"
                        class="ml-4"
                        icon
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <div class="mb-4">
                        {{ translate('common.adjust_statistics_help') }}
                    </div>

                    <div class="text--secondary font-weight-medium">
                        {{ translate('common.select_statistics') }}
                    </div>
                    <v-checkbox
                      v-for="_, key in states"
                      :key="key"
                      v-model="internal_value"
                      :value="key"
                      :label="translate(`common.${key}`)"
                      :disabled="saving"
                      dense
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        :disabled="saving"
                        @click="show_dialog = false"
                        outlined
                    >
                        {{ translate('common.cancel') }}
                    </v-btn>
                    <v-btn
                        :disabled="saving"
                        @click="submit_adjust_statistics"
                        color="primary"
                    >
                        {{ translate('common.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </section>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { item_mixin } from '@/mixins/item'
import common_mixin from '@/mixins/common/mixin'
import access_mixin from '@/mixins/common/access_mixin'
import TheDashboardStatisticsTrend from '@/components/the_dashboard/statistics/trend'

export default {
    name: 'TheDashboardStatistics',
    props: {
        creditor_id: {
            type: String,
        },
        show: {
            type: Boolean,
        },
    },
    mixins: [item_mixin, access_mixin, common_mixin],
    components: {
        TheDashboardStatisticsTrend,
    },
    data() {
        return {
            states: {
                reminder: null,
                debt_collection: null,
                enforcement: null,
                debt_surveillance: null,
                amortization: null,
            },
            last_thirty_days: {},
            previous_thirty_days: {},

            show_dialog: false,
            internal_value: [],
            saving: false,
        }
    },
    computed: {
        ...mapGetters(['solicitor']),
        ...mapState(['creditor_settings']),
        enabled_states () {
            return Object.keys(this.states)
              .filter(state => !this.creditor_settings[this.creditor_id]?.settings?.dashboard_statistics?.hidden?.includes(state))
        },
    },
    watch: {
        creditor_id() {
            this.get_statistics()
            this.get_trends()
        },
    },
    methods: {
        async get_statistics() {
            this.reset_statistics()

            const where = {
                closed: { $exists: false },
            }
            if (this.creditor_id) {
                where['creditor'] = this.creditor_id
            }
            for (const state in this.states) {
                this.api_get({
                    url: '/cases',
                    params: {
                        where: {
                            state: state,
                            ...where,
                        },
                        projection: {
                            _id: 1,
                        },
                        max_results: 1,
                    },
                }).then((response) => (this.states[state] = response.data['_meta']['total']))
            }
        },
        async get_trends() {
            this.reset_trends()

            let url = null

            if (this.creditor_id) {
                url = '/statistics--creditor-days'
            } else if (this.solicitor) {
                url = null
            } else if (this.has_get_resource_access('statistics--account-days')) {
                url = '/statistics--account-days'
            }

            if (!url) return

            const where = {}
            if (this.creditor_id) {
                where['creditor'] = this.creditor_id
            }

            const promises = [
                ['last_thirty_days', 1],
                ['previous_thirty_days', 2],
            ].map(async ([bucket, page]) => {
                const response = await this.api_get({
                    url,
                    params: {
                        max_results: 30,
                        page,
                        sort: '-date',
                        where,
                    },
                })

                for (const state in this[bucket]) {
                    this[bucket][state] = response['data']['_items'].reduce(
                        (total, item) => total + item['states'][state],
                        0
                    )
                }
            })

            await Promise.all(promises)
        },
        initialize() {
            for (const state in this.states) {
                this.$set(this.last_thirty_days, state, null)
                this.$set(this.previous_thirty_days, state, null)
            }
        },
        reset_statistics() {
            for (const state in this.states) {
                this.states[state] = null
            }
        },
        reset_trends() {
            for (const state in this.states) {
                this.last_thirty_days[state] = null
                this.previous_thirty_days[state] = null
            }
        },
        show_adjust_statistics () {
            this.internal_value = [...this.enabled_states]
            this.show_dialog = true
        },
        async submit_adjust_statistics () {
            this.saving = true
            try {
                const disabled_states = Object.keys(this.states)
                  .filter(state => !this.internal_value.includes(state))
                this.update_creditor_setting(this.creditor_id, 'settings.dashboard_statistics.hidden', disabled_states)
                await this.save_creditor_settings_updates(this.creditor_id)
                this.show_dialog = false
            } finally {
                this.saving = false
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        this.initialize()
        this.get_statistics()
        this.get_trends()
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style lang="scss" scoped>
.grid {
    display: grid;
    grid-template-columns: repeat(var(--states, 5), minmax(0, 1fr));
    gap: 24px;
    @media only screen and (max-width: 900px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.count {
    display: flex;
    align-items: baseline;

    div.number {
        line-height: 32px;
        font-weight: 500;
        font-size: 32px;
    }

    div.st {
        font-weight: 500;
        font-size: 16px;
    }
}
</style>
