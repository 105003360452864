<template>
    <div>
        <slot
            :open_in_app="open_in_app"
            :open_in_new_window="open_in_new_window"
            name="activator"
        >
            <div
                v-if="!hideButtons"
                :class="{
                    'justify-center': center,
                    'justify-end': !center,
                }"
                class="d-flex"
            >
                <v-btn
                    v-if="!hideOpenInNewWindowButton"
                    :color="color"
                    :disabled="disabled"
                    :small="small"
                    @click="open_in_new_window"
                    @click.stop
                    class="mr-1"
                    icon
                >
                    <v-icon :small="small">
                        mdi-open-in-new
                    </v-icon>
                </v-btn>
                <v-btn
                    v-if="!hideOpenInAppButton"
                    :color="color"
                    :disabled="disabled"
                    :small="small"
                    @click="open"
                    @click.stop
                    icon
                >
                    <MediaFileIcon
                        :small="small"
                        :value="internal_media_file_data"
                        fallback_icon="mdi-open-in-app"
                    />
                </v-btn>
            </div>
        </slot>

        <v-progress-circular
            v-if="loading && !hideLoaderOutsideDialog"
            id="prints_outside_spinner"
            class="app-object--absolute-center app-layout--level-10"
            color="primary"
            indeterminate
        />
        <AppDialog
            v-if="internal_media_file_data"
            v-model="internal_value"
            :fullscreen="fullscreen"
            :title="title || internal_media_file_data.filename"
            height="90vh"
            remove_border_top_radius
        >
            <template #toolbar_actions>
                <v-btn
                    @click="fullscreen = !fullscreen"
                    icon
                >
                    <v-icon> {{ fullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}</v-icon>
                </v-btn>
            </template>
            <template #default>
                <div class="iframe-container">
                    <v-progress-circular
                        v-if="loading"
                        id="prints_inner_spinner"
                        class="app-object--absolute-center app-layout--level-10"
                        color="primary"
                        indeterminate
                    />
                    <template v-if="internal_media_url">
                        <v-img
                            v-if="content_type && content_type.startsWith('image/')"
                            :src="internal_media_url"
                            height="100%"
                            max-height="100%"
                            width="100%"
                            max-width="100%"
                            contain
                        />
                        <video
                            v-else-if="content_type && content_type.startsWith('video/')"
                            controls
                        >
                            <source
                                :src="internal_media_url"
                                :type="content_type"
                            >
                        </video>
                        <object
                            v-else-if="content_type === 'application/pdf'"
                            :key="internal_media_url"
                            :data="internal_media_url"
                            :type="content_type"
                        />
                        <iframe
                            v-else
                            ref="iframe"
                            :src="internal_media_url"
                            sandbox
                        />
                    </template>
                </div>
            </template>
        </AppDialog>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'
import AppDialog from '@/components/app_dialog/index.vue'
import MediaFileIcon from '@/components/media_file_icon/index.vue'

export default {
    name: 'MediaFileDialog',
    components: { MediaFileIcon, AppDialog },
    mixins: [common_mixin],
    props: {
        value: {
            type: Boolean,
        },
        resource: String,
        id: String,
        document: Object,
        media_file_dotted_path: String,
        title: {
            type: String,
        },
        disabled: Boolean,
        center: Boolean,
        hideOpenInAppButton: Boolean,
        hideOpenInNewWindowButton: Boolean,
        hideButtons: Boolean,
        hideLoaderOutsideDialog: Boolean,
        media_url: String,
        media_file_data: Object,
        small: Boolean,
        color: {
            type: String,
            default: 'primary',
        },
    },
    data() {
        return {
            lazy_value: undefined,
            lazy_document: undefined,
            lazy_media_url: undefined,
            lazy_media_file_data: undefined,
            loading: false,
            render_key: 0,
            fullscreen: false,
        }
    },
    computed: {
        internal_media_file_data: {
            get() {
                if (this.internal_document && this.media_file_dotted_path) {
                    return this.deep_get(this.internal_document, this.media_file_dotted_path)
                }

                return this.media_file_data || this.lazy_media_file_data
            },
            set(val) {
                this.lazy_media_file_data = val
                this.$emit('update:media_data')
            },
        },
        internal_media_url: {
            get() {
                return this.media_url || this.lazy_media_url
            },
            set(val) {
                this.lazy_media_url = val
                this.$emit('update:media_url', val)
            },
        },
        internal_document: {
            get() {
                return this.document || this.lazy_document
            },
            set(val) {
                this.lazy_document = val
                this.$emit('update:document', val)
            },
        },
        internal_value: {
            get() {
                return this.value || this.lazy_value
            },
            set(val) {
                this.lazy_value = val
                this.$emit('input', val)
            },
        },
        content_type () {
            return this.internal_media_file_data?.content_type
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value(val) {
            if (val === true) {
                if (this.touch_is_enabled()) {
                    console.log('open in new window touch is enabled')
                    this.open_in_new_window()
                } else {
                    this.load_media_file()
                }
            } else {
                this.internal_media_url = null
            }
            this.internal_value = val
        },

        id: {
            handler(val) {
                if (val && !this.internal_document) {
                    this.get_document()
                }
            },
            immediate: true,
        },
        internal_media_url(val) {
            if (val) {
                this.update_render_key_when_iframe_is_loaded(0)
            }
        },
    },
    methods: {
        open() {
            if (this.touch_is_enabled()) {
                this.open_in_new_window()
            } else {
                this.open_in_app()
            }
        },
        async open_in_app() {
            console.log('open in app', this.internal_media_url)
            await this.load_media_file()
            this.internal_value = true
        },
        async open_in_new_window() {
            await this.load_media_file()
            let new_tab = window.open(this.internal_media_url, '_blank')
            new_tab.focus()
            if (this.internal_value === true) this.internal_value = false
        },
        get_document() {
            this.api_call_wrapper(
                async () => {
                    let result = await this.api_get({
                        url: `/${this.resource}/${this.id}`,
                    })
                    this.internal_document = result.data
                },
                this.error_callback,
                false
            )
        },
        async load_media_file() {
            if (this.internal_media_url) {
                return
            }

            const result = await this.api_get({
                url: `/media--url/${this.resource}/${this.internal_document['_id']}/${this.internal_media_file_data['id']}`,
            })
            this.internal_media_url = result['data']['url']
            this.$nextTick(() => {
                this.render_key += 1
            })
        },
        update_render_key_when_iframe_is_loaded(ticks) {
            if (ticks > 10) return

            this.$nextTick(() => {
                let iframe = this.$refs.iframe

                if (iframe && iframe.offsetHeight) {
                    this.render_key += 1
                } else {
                    ticks += 1
                    this.update_render_key_when_iframe_is_loaded(ticks)
                }
            })
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped>
.iframe-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.iframe-container iframe,
.iframe-container video,
.iframe-container object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: contain;
    border: none;
    overflow: hidden;
}
</style>
